import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";

import { PageLayout } from "./components/PageLayout";
import { b2cPolicies, protectedResources } from "./authConfig";
import { compareIssuingPolicy } from "./utils/claimUtils";

import { CreateClient } from "./pages/createClient/CreateClient";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Table } from "./pages/table/Table";
import { ClientInfoPage } from "./pages/ClientInfoPage/ClientInfoPage";
import { BillingReportPage } from "./pages/bilingReport/BillingReportPage";
import { SchedulePage } from "./pages/schedulePage/SchedulePage";
import { ProtectedRoute } from "./components/protectedRoute/ProtectedRoute";
import { PsychosocialAssessmentPage } from "./pages/PsychosocialAssessmentPage/PsychosocialAssessmentPage";
import { SignatureDownload } from "./pages/signatureDownload/SignatureDownload";
import { BilingReport } from "./components/bilingReport/BilingReport";
import { AuthorizationReportPage } from "./pages/authorizationReportPage/AuthorizationReportPage";
import { CalendarComponent } from "./components/calendar/Calendar";
import { ServiceCalendarPicker } from "./components/calendar/ServiceCalendarPicker";
import { UsersPage } from "./pages/usersPage/UsersPage";
import { UserSchedulePage } from "./pages/userSchedulePage/UserSchedulePage";
import { EighteenUpRatingRounded } from "@mui/icons-material";
import { ERAPage } from "./pages/eraPage/ERAPage";
import { TestPage } from "./pages/testPage/TestPage";




const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Suez One !important",
      fontSize: "30px",
      fontWeight: "400",
      lineHeight: "39.18px",
    },
    h2: {
      fontFamily: "Suez One !important",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "26px",
      color: "rgba(66, 129, 171, 1)",
    },
    h6: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "16.8px",
    },
    subtitle1: {
      fontSize: "10px",
      fontWeight: "400",
      lineHeight: "12px",
    },
    body1: {
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "14px",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: "700",
      lineHeight: "14px",
    },
    button: {
      font: '600 12px "Open Sans"',
    },
    accordion: {
      font: '400 12px "Open Sans"',
      textTransform: "uppercase",
    },
    link: {
      font: '400 12px "Open Sans"',
    },
  },
  palette: {
    primary: {
      main: "rgba(101, 157, 189, 1)",
      light: "rgb(179 206 222)",
      dark: "rgba(66, 129, 171, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    info: {
      main: "rgba(255, 107, 87, 1)",
      contrastText: "rgba(255, 255, 255, 1)",
    },
    background: {
      main: "rgba(189, 152, 106, 1)",
      active: 'rgba(101, 157, 189, 1)',
      menu: 'rgba(142, 135, 65, 1)',
      img: 'rgb(234, 206, 183)',
      step: 'rgba(218, 173, 134, 1)',
      mainBlue:'rgba(239, 245, 248, 1)',
      header: 'rgba(101, 157, 189, 1)',
      popupHeader: 'rgba(199, 219, 232, 1)'

    },
    link: {
      main: "rgba(46, 123, 163, 1)",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "14px",
          textAlign: "left",
          marginBottom: "9px",
          color: "rgba(66, 129, 171, 1)",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Open Sans",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 11px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: "rgba(248, 239, 231, 1)",
          height: "30px",
          padding: "5px",
          "& fieldset": { border: "none" },
          "& .trash-icon": { display: "none" },
        },
      },
    },
    MuiAutocomplete:{
      styleOverrides: {
        root: {
          background: "rgba(248, 239, 231, 1)",
          "& fieldset": { border: "none" },
          borderRadius: '3px',
          "& .MuiOutlinedInput-root": {
           padding: 0,
           paddingRight: '25px',
           paddingLeft: '15px'
          },
          "& .MuiInputBase-adornedStart": {
            height: '100%',
          }
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.1)",
          "&.Mui-active": {
            color: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "rgba(66, 129, 171, 0.5)",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "rgba(66, 129, 171, 0.5)",
         
        },
      },
    },
  },
});

const Pages = () => {
  const {instance } = useMsal();


  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow
         */

        if (
          compareIssuingPolicy(
            event.payload.idTokenClaims,
            b2cPolicies.names.forgotPassword
          )
        ) {
          let signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [
              ...protectedResources.apiTodoList.scopes.read,
              ...protectedResources.apiTodoList.scopes.write,
            ],
          };
          instance.loginRedirect(signUpSignInFlowRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes("AADB2C90118")) {
          const resetPasswordRequest = {
            authority: b2cPolicies.authorities.forgotPassword.authority,
            scopes: [],
          };
          instance.loginRedirect(resetPasswordRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [instance]);

  return (
    <Routes>
      <Route path="/client/:id" element={<ProtectedRoute />}>
        <Route path="" element={<ClientInfoPage />} />
      </Route>
      <Route path="/psychosocialAssessment/:id/:formId/:clientServiceId" element={<ProtectedRoute />}>
        <Route path="" element={<PsychosocialAssessmentPage />} />
      </Route>
      <Route path="/createClient" element={<ProtectedRoute />}>
        <Route path="" element={<CreateClient />} />
      </Route>
      <Route path="/signature" element={<ProtectedRoute />}>
        <Route path="" element={<SignatureDownload />} />
      </Route>
      <Route path="/bilingReport" element={<BillingReportPage/>} />
      <Route path="/authorizationReport" element={<AuthorizationReportPage />} />
      <Route path="/calendar" element={<SchedulePage />} />
      <Route exact path="/home" element={<Table />} />
      <Route exact path="/" element={<Table />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/userSchedule" element={<UserSchedulePage />} />
      <Route path="/era" element={<ERAPage />} />
      <Route path="/test" element={<TestPage />} />

    </Routes>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <PageLayout>
          <Pages />
        </PageLayout>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
