import { useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function AuthConfirmProcessingDialog({
  open,
  setOpen,
  titleText,
  contentText,
  handleAction,
  onResponse,
  message,
}) {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    if (onResponse) onResponse('close');
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      if (handleAction) await handleAction(); // Call the generic action handler
      if (onResponse) onResponse('completed');
    } catch (error) {
      console.error("Error processing action:", error);
      if (onResponse) onResponse('error');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setOpen(false);
        if (onResponse) onResponse('close');
      }, 5000); // Auto-close after 5 seconds
    }
  };

  const isError = typeof message === 'string' && message.startsWith("Error");
  const isNoRecords = typeof message === 'string' && message.includes("No Records");

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {titleText || 'Default Title'}
      </DialogTitle>
      <DialogContent>
        {!message && (
          <DialogContentText>
            {contentText || ''}
          </DialogContentText>
        )}
        {loading && <LinearProgress sx={{ marginTop: '4px' }} />}
        {message && (
          <DialogContentText
            style={{
              marginTop: '16px',
              display: 'flex',
              alignItems: 'center',
              color: isError ? 'red' : 'green',
            }}
          >
            {isError ? (
              <ErrorOutlineIcon style={{ marginRight: '8px', color: 'red' }} />
            ) : (
              <CheckCircleIcon style={{ marginRight: '8px', color: 'green' }} />
            )}
            {message}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading || message}>
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary" disabled={loading || message}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
