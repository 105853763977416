import Box from "@mui/material/Box";
import { FieldContainer, FieldTitle, FieldValue, Role, SignatureButton, SignatureContainer, SignatureControls, SignatureField, SignatureIcon, SignatureImage } from "./Signature.styled";
import penSvg from "../../../shared/icons/pen.svg";
import crossInCircleSvg from "../../../shared/icons/crossInCircle.svg";
import historySvg from "../../../shared/icons/history.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import { Alert, AlertTitle, Button } from '@mui/material';

export const SignatureItem = (props) => {
    const { self, info, userRoles, signatures, signatureInfo, getSignatureId, getUnsignId } = props;

    const [sign, setSign] = useState(false);
    const [signature, setSignature] = useState();
    const [dateOfSigning, setDateOfSigning] = useState();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);


    const getRoleById = (roleId) => {
        const foundRole = userRoles?.find(role => role.id === roleId);

        return foundRole ? foundRole.name : null;
    }

    //   const getSignatureById = () => {

    //     const currentSignature = signatures.find(signature => signature.formRequiredSignatureId === info?.signature?.id);

    //     return currentSignature || null;
    //   }
    const setSignForm = (value) => {
        if (value === false) {
            if (signature) {
                setSign(value)
                getUnsignId(signatureInfo?.id)
                getSignatureId(false)
            } else {
                setSign(value)
            }
            setDateOfSigning(null);
        } else {
            if (signature) {
                setSign(value)
                getUnsignId(false)
            } else {
                setSign(value)
            }
            getSignatureId(signatureInfo?.id);
            setDateOfSigning(moment().format("MM/DD/YYYY"));
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000); // Hide after 3 seconds
        }
        
    }


    const getSignatureImage = () => {
        if (signature?.formRequiredSignatureId === signatureInfo?.id && sign) {
            return `data:image/png;base64,${signature?.base64Data}`
        } else if (signature?.formRequiredSignatureId !== signatureInfo?.id && sign) {
            return `data:image/png;base64,${info?.signature?.base64Data}`
        }

    }

    useEffect(() => {
        if (self && sign) {
            getSignatureId(signatureInfo?.id);
        }
    }, [self, sign]);

    useEffect(() => {
        if (signatures) {
            console.log("signature", signatures)
            const currentSignature = signatures.find(signature => signature.formRequiredSignatureId === signatureInfo?.id);
            console.log("signature.formRequiredSignatureId", info)
            console.log("currentSignature", currentSignature)
            console.log("signatureInfo", signatureInfo)
            if (currentSignature?.formRequiredSignatureId === signatureInfo?.id) {
                console.log("signature found", currentSignature)
                setSignature(currentSignature)
                setSign(true)
                setDateOfSigning(moment(currentSignature.dateOfSigning).format("MM/DD/YYYY"));
            }
        }
    }, [signatures, info]);

    return (
        <>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "15px",
                marginBottom: "15px"
            }}>
                {showSuccessAlert && (
                    <Alert
                        severity="info"
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            zIndex: 1
                        }}
                        onClose={() => setShowSuccessAlert(false)}
                    >
                        Form signed successfully!
                    </Alert>
                )}
                <Role>{getRoleById(signatureInfo.roleId)}</Role>
                <FieldContainer>
                    <FieldTitle>
                        Name
                    </FieldTitle>
                    <FieldValue>
                        {self ? `${info?.firstName} ${info?.lastName}` : null}
                    </FieldValue>
                </FieldContainer>
                <FieldContainer>
                    <FieldTitle>
                        Effective Date
                    </FieldTitle>
                    <FieldValue>{dateOfSigning || ''}</FieldValue>
                    {/* 07/10/2023 */}
                </FieldContainer>
                <FieldContainer>
                    <FieldTitle>
                        Signature
                    </FieldTitle>
                    <SignatureField sx={{ background: self ? '#F8EFE7' : '#f8fafb' }}>
                        <SignatureContainer>
                            {sign && <SignatureImage component="img" src={getSignatureImage()} alt="" />}
                        </SignatureContainer>
                        {/* <img src={`data:image/png;base64,${info?.signature?.base64Data}`}/> */}
                        {self && <SignatureControls>
                            <SignatureButton onClick={() => setSignForm(true)}>
                                <SignatureIcon component="img" src={penSvg} alt="" />
                                Use Your Saved Signature
                            </SignatureButton>
                            <SignatureButton onClick={() => setSignForm(false)}>
                                <SignatureIcon component="img" src={crossInCircleSvg} alt="" />
                                Clear
                            </SignatureButton>
                        </SignatureControls>}
                    </SignatureField>
                </FieldContainer>
                <FieldContainer>
                    <FieldTitle>
                        <SignatureIcon component="img" src={historySvg} alt="" />
                        Assignment History
                    </FieldTitle>
                </FieldContainer>
            </Box>
        </>
    );
};
