import Box from "@mui/material/Box";
import { SignatureItem } from "./SignatureItem";
import { useGetUsersRolesQuery, useSignFormMutation, useUnsignFormMutation, } from "../../../services/ClientService";
import useFetchWithMsal from "../../../utils/useFetchWithMsal";
import { useEffect, useState } from "react";

export const Signatures = (props) => {

  const { skip } = useFetchWithMsal();

  const { signatures, basicInfo, requiredSignatures, getSignatureId, getUnsignId, clientServiceId } = props;
  const { data: userRoles, isLoading } = useGetUsersRolesQuery({ skip });
  const [sortedSignatures, setSortedSignatures] = useState([]);
  const [signForm] = useSignFormMutation();
  const [unsignForm] = useUnsignFormMutation();

  useEffect(() => {
    if (requiredSignatures) {
      const sortedData = [...requiredSignatures].sort((a, b) => a.priority - b.priority); 
      setSortedSignatures(sortedData);
    }
  }, [requiredSignatures]);

  useEffect(() => {
    console.log("basicInfo:",basicInfo);
    console.log("signatures",signatures);
    if (props.signatureId) {
      signForm({ userId: basicInfo.id, signatureId: props.signatureId });
    }
    if (props.unsignId) {
      unsignForm({ userId: basicInfo.id, signatureId: props.unsignId });
    }
  }, [props.signatureId, props.unsignId]);
  return (
      <>
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}>
          {sortedSignatures.map(item => (
            <SignatureItem
              key={item.id}
              info={basicInfo}
              self={item?.roleId === basicInfo?.roleId}
              userRoles={userRoles}
              signatures={signatures}
              signatureInfo={item}
              getSignatureId={getSignatureId}
              getUnsignId={getUnsignId}
            />
          ))}
        </Box>
      </>
  );
};
