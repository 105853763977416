import React from 'react';
import { PsychosocialAssessment } from '../../psychosocialAssessment/PsychosocialAssessment';
import styled from "styled-components";

const PsychosocialAssessmentPageContainer = styled.div`
  display: flex;
  max-width: 1040px;
  margin-left: 10px;
  background: lightgrey;
`;


export const DetailPanelContent = ({ row }) => {
  // Log the prop values for debugging
  console.log('DetailPanelContent Props:', {
    propClientId: row.clientId,
    propFormId: row.clientId,
    propClientServiceId: row.id,
  });

  return (
    <PsychosocialAssessmentPageContainer>
      <PsychosocialAssessment
        propClientId={row.clientId}
        propFormId={7}
        propClientServiceId={row.id}
      />
    </PsychosocialAssessmentPageContainer>
  );
};