import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';

import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import { useGetClientFormListQuery } from "../../../../services/ClientService";
import { useParams } from "react-router-dom";
import { BilingReport } from "../../../bilingReport/BilingReport";


const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));


export const ProgressNotes = () => {
  const { skip } = useFetchWithMsal();

  const { id } = useParams();
  const { data: clientFormList, isLoading } = useGetClientFormListQuery(id, { skip });

  const [rows, setRows] = useState([]);

  let originalRows = clientFormList

  useEffect(() => {
    if(originalRows) {
      setRows(originalRows)
    }
  },[clientFormList, originalRows] );

  return (
    <StyledPaper sx={{ width: '100%', margin: '0px'}}>
      {isLoading}
      <BilingReport
       fromSource="client"
       
      />
    </StyledPaper>
  );
}