import React from "react";
import { useField } from "formik";
import { FormHelperText, InputLabel, TextField } from "@mui/material";

const InputField = ({ label, height, width, type, multiline, required, maxLength, step, disabled, ...props }) => {
  // useField hook provides field and meta objects for the form field
  const [field, meta] = useField(props);

  return (
    <>
      {label && (
        <InputLabel htmlFor={field.name} sx={{ height: "14px" }}>
          {label}
          {required && "*"}
        </InputLabel>
      )}
      <TextField
        {...field}
        {...props}
        type={type || "text"}
        size="small"
        error={meta.touched && !!meta.error}
        helperText={meta.touched && meta.error ? meta.error : ""}
        InputProps={{
          style: { height: height || "30px", maxWidth: width || "290px", width: "100%", border: meta.error ? "1px solid red" : "none" },
          inputProps: { maxLength: maxLength, step: step || "1" },
        }}
        multiline={multiline || false}
        required={required}
        disabled={!!disabled}
      />
    </>
  );
};

export default InputField;