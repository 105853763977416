import React, {useState, useEffect} from "react";
import { useGetPossibleValueByIdQuery, useGetSearchPossibleValuesQuery } from "../../services/ClientService";
import AutocompleteField from "../../shared/components/AutocompleteField";

const SearchableSelect = (props) => {
  const { value, name, setValue, error,label, data, width } = props;
  
  const [searchInputValue, setSearchInputValue] = useState('');
  const [inputValueId, setInputValueId] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [shouldFetchValueById, setShouldFetchValueById] = useState(false);
  const [shouldFetchPossibleValuesQuery, setShouldFetchPossibleValuesQuery] = useState(false);

  // const { data: searchPossibleValues } = useGetSearchPossibleValuesQuery({value: searchInputValue, id: name});
  const { data: searchPossibleValues } = useGetSearchPossibleValuesQuery(
    shouldFetchPossibleValuesQuery ? { value: searchInputValue, id: name } : null,
    {
      skip: !shouldFetchPossibleValuesQuery
    }
  );
  // const { data: valueById } = inputValueId ? useGetPossibleValueByIdQuery(inputValueId);
  const { data: valueById } = useGetPossibleValueByIdQuery({ value: inputValueId, id: name }, {
    skip: !shouldFetchValueById
  });

  useEffect(() => {
    if (inputValueId) {
      setShouldFetchValueById(true);
    } else {
      setShouldFetchValueById(false);
    }
  }, [inputValueId]);

  useEffect(() => {
    if (searchInputValue) {
      setShouldFetchPossibleValuesQuery(true);
    } else {
      setShouldFetchPossibleValuesQuery(false);
    }
  }, [searchInputValue]);

  const onInputChange = (value) => {
    if(value.length >= 2){
      setSearchInputValue(value)
    }
  }

  useEffect(() => {
    if(valueById){
      setInputValue(valueById)
    }
  }, [valueById]);

  useEffect(() => {
    setInputValueId(value)
  }, [value]);

  const onChange = (value) => {
    setValue(name, value?.id ? value?.id : '')
    setInputValue(value ? value : '')
  };

  return (
    <>
      <AutocompleteField
        isError={!!error}
        errorText={error}
        onChange={onChange}
        possibleValues={searchPossibleValues}
        onInputChange={onInputChange}
        label={label}
        data={data}
        width={width}
        value={inputValue}
      />
    </>
  );
};
export default React.memo(SearchableSelect);
