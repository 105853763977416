import React from "react";
import "./AuthorizationReportPage.css";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { AuthorizationsReport } from "../../components/authorizationReport/AuthorizationsReport";

export const AuthorizationReportPage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <div className="clientContainer container">
          <AuthorizationsReport fromSource="authorization"/>
        </div>
      ) : null}
    </AuthenticatedTemplate>
  );
};
