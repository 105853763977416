import React, { useState, useEffect } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/system";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import SelectField from "../../shared/components/SelectField";
import AutocompleteFieldForFilters from "../../shared/components/AutocompleteFieldForFilters";
import {
  useGetClientCounselorsQuery,
  useGetClientHousingsQuery,
  useGetClientStatusesQuery,
  useGetCompanyBranchesQuery,
  useGetTracksQuery,
} from "../../services/UtilityService";
import {
  useGetAuthorizationListQuery,
  useGetBillingStatusQuery,
  useGetLevelOfCareQuery,
  useGetServicesQuery,
  useGetAssignmentClientsMutation,
} from "../../services/ClientService";
import { useDispatch, useSelector } from "react-redux";
import {
  setClient,
  setService,
  setBillingStatus,
  setLevelofCare,
  setAuthStatus,
} from "../../store/slices/FilterSlice";
import useFetchWithMsal from "../../utils/useFetchWithMsal";

const StyledPopover = styled(Popover)({
  "& .MuiPaper-root": {
    padding: "24px",
    height: "360px",
    width: "260px",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
  },
});
const StyledFormGroupContainer = styled(Box)({
  padding: "19px 17px",
  background: "rgba(237, 238, 241, 0.3)",
  height: "220px",
  overflow: "auto",
  fontFamily: "Open Sans",
  fontSize: "12px",
  fontWeight: 600,
});
const StyledTracksOptionContainer = styled(Box)({
  display: "flex",
  width: "150px",
  justifyContent: "space-between",
  marginBottom: "10px",
  "& .MuiTypography-root": {
    cursor: "pointer",
  },
});

const StyledButton = styled(Button)(({ theme, color, backgroundColor }) => ({
  //background: backgroundColor ? backgroundColor : '#659DBD',
  font: theme.typography.button.font,
  //color: color ? '#fff' : '#659DBD',
  cursor: "pointer",
  padding: "4px 15px",
  textTransform: "uppercase",
  marginRight: "5px",
  '&:hover': {
    backgroundColor: backgroundColor,
    opacity: 0.8
  },
  "& .MuiButtonBase-root-MuiButton-root": {
    color: color
  },
  'svg': {
    color: '#fff'
  }
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    height: "15px",
    minWidth: "15px",
    padding: "0",
    right: "-16px",
    top: "7px",
  },
}));

export const BillingReportFilter = (props) => {
  const { onApplyFilters, onResetFilters, filterSet } = props;
  const { skip } = useFetchWithMsal();

  /*   const { data: statues } = useGetClientStatusesQuery('', {skip});
    const { data: counselors } = useGetClientCounselorsQuery('', {skip});
    const { data: housings } = useGetClientHousingsQuery('', {skip}); */


  const [getClients, { data: clients }] = useGetAssignmentClientsMutation()
  useEffect(() => {
    getClients({
      startDate: null,
      endDate: null,
      isShowAll: true,
    });
  }, []);

  useEffect(() => {
    if (clients) {
      // Transform the data to an array of { id, name } objects
      const transformedClients = clients.map(client => ({
        id: client.id,
        name: `${client.firstName} ${client.lastName}`
      }));

      console.log('Transformed clients data:', transformedClients);
      setTransformedClients(transformedClients);

      // Now you can use transformedClients in your component
      // or set it to state if needed
    }
  }, [clients]);
  const [transformedClients, setTransformedClients] = useState([]);


  const { data: services } = useGetServicesQuery('', { skip });
  const { data: levelofcares } = useGetLevelOfCareQuery('', { skip });
  const { data: billingstatuses } = useGetBillingStatusQuery('', { skip });
  const { data: authstatuses } = useGetAuthorizationListQuery('', { skip });


  const dispatch = useDispatch();
  const { service, levelofcare, billingstatus, authstatus, client } = useSelector(
    (state) => state.filter
  );
  const selectedClient = client && client.id ? transformedClients.find(item => item.id === client.id) : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const [trackCount, setTrackCount] = useState(0);
  const open = Boolean(anchorEl);

  // const handleCheckboxChange = (item) => (event) => {
  //   if (event.target.checked) {
  //     setTrackCount(trackCount + 1);
  //     dispatch(setTracks([...tracks, item]));
  //   } else {
  //     setTrackCount(trackCount - 1);
  //     const filteredArray = tracks.filter((track) => track !== item);
  //     dispatch(setTracks(filteredArray));
  //   }
  // };
  // const onCheckAll = () => {
  //   const IDs = tracksData.map((track) => track.id);
  //   dispatch(setTracks(IDs));
  // };
  // const onUncheckAll = () => {
  //   dispatch(setTracks([]));
  // };
  const onFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onBillingStatusChange = (event) => {
    console.log('Dispatching billing status data:', event);
    dispatch(setBillingStatus(event.target.value));
  };
  const onServiceChange = (event) => {
    dispatch(setService(event.target.value));
  };
  const onClientChange = (event) => {
    console.log('Dispatching client data:', event);
      dispatch(setClient(event.id));
  };
  const onLevelofCareChange = (event) => {
    dispatch(setLevelofCare(event.target.value));
  };
  const onAuthStatusChange = (event) => {
    dispatch(setAuthStatus(event.target.value));
  };
  const onReset = () => {
    dispatch(setBillingStatus([]));
    dispatch(setService([]));
    dispatch(setClient([]));
    dispatch(setLevelofCare([]));
    dispatch(setAuthStatus([]));
    setAnchorEl(null);
    onResetFilters();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleApply = () => {
    onApplyFilters();
    setAnchorEl(null);
  };

  return (
    <Toolbar
    >
      <StyledButton
        variant="outlined"
        onClick={onFilterClick}
        startIcon={<FilterListIcon style={{ color: '#659DBD' }} />}
        color={billingstatus || service || client || authstatus || levelofcare ? "info" : "link"}
      >
        Filter
      </StyledButton>

      <StyledPopover
        id={"filter-popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <SelectField
          name={"BillingStatus"}
          label={"Status"}
          data={billingstatuses}
          onChange={onBillingStatusChange}
          value={billingstatus}
          labelType={"subtitle2"}
          marginBottom="4px"
          fullWidth
        />
        <SelectField
          name={"Service"}
          label={"Service"}
          data={services}
          onChange={onServiceChange}
          value={service}
          labelType={"subtitle2"}
          marginBottom="4px"
          fullWidth
        />

        {filterSet ==! "client" && <AutocompleteFieldForFilters
          name={"Client"}
          label={"Client"}
          data={transformedClients}
          onChange={onClientChange}
          value={selectedClient}
          labelType={"subtitle2"}
          marginBottom="4px"
          fullWidth
        />}
        <SelectField
          name={"LevelofCare"}
          label={"Level of Care"}
          data={levelofcares}
          onChange={onLevelofCareChange}
          value={levelofcare}
          labelType={"subtitle2"}
          marginBottom="4px"
          fullWidth
        />
        <SelectField
          name={"Authorization"}
          label={"Authorization"}
          data={authstatuses}
          onChange={onAuthStatusChange}
          value={authstatus}
          labelType={"subtitle2"}
          marginBottom="4px"
          fullWidth
        />
        <Box sx={{ display: "flex" }}>
          <StyledButton onClick={handleApply} backgroundColor="#fff">
            Apply
          </StyledButton>
          <StyledButton onClick={onReset} backgroundColor="#fff">
            Reset
          </StyledButton>
        </Box>
      </StyledPopover>
    </Toolbar>
  );
};
