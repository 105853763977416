import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Modal, Dialog } from "@mui/material";
import DatePickerField from "../../../common/DatePickerField";
import SelectField from "../../../common/SelectFieldForForm";
import CheckboxField from "../../../common/CheckboxField";
import InputField from "../../../common/InputField";
import { AddNewButton, MedicationBody, MedicationFragment, MedicationHeader, PopupFooter } from "./MedicationDetailsForm.styled";
import { Delete, Medication, Assignment, LocalHospital, Info } from "@mui/icons-material";
import { useGetClientMedicationPrescriberQuery } from "../../../../services/ClientService";
import useFetchWithMsal from "../../../../utils/useFetchWithMsal";
import RadioGroup from "../../../common/RadioGroup";
import { AdministrationInstructionsField } from "./AdministrationInstructionsField";
import { PopupBody, PopupButton } from "../../../calendar/Calendar.styled";
import CloseIcon from "@mui/icons-material/Close";
import { AdministrationInstructionsGrid } from "./AdministrationInstructionsGrid";
import roundedPlus from "../../../../shared/icons/roundedPlus.svg";


export const MedicationPopup = ({
  open,
  medicationDetails,
  onClose,
  index,
  setMedicationDetails,
  handleAddAdministrationInstructions,
  selectedAdministrationInstructionsIndex,
  setSelectedAdministrationInstructionsIndex,
  doseType,
  frequency,
  method,
  onSave,
}) => {

  const [initialValues, setInitialValues] = useState(null);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [medicationIndexToDelete, setMedicationIndexToDelete] = useState(null);
  const [unsavedChangesModalOpen, setUnsavedChangesModalOpen] = useState(false);

  const handleOpenUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(true);
  };

  const handleCloseUnsavedChangesModal = () => {
    setUnsavedChangesModalOpen(false);
  };


  useEffect(() => {
    if (!initialValues) {
      setInitialValues(medicationDetails[index]);
    }
  }, [index]);

  const handleCancel = () => {
    if (initialValues) {
      medicationDetails[index]?.newMedication ?
        setMedicationDetails(prevDetails => {
          const updatedDetails = [...prevDetails];
          updatedDetails.splice(index, 1);
          return updatedDetails;
        }) :
        setMedicationDetails(prevDetails => {
          const updatedDetails = JSON.parse(JSON.stringify(prevDetails));
          updatedDetails[index] = initialValues;
          return updatedDetails;
        });
    }
    setInitialValues(null)
    handleCloseUnsavedChangesModal();
    onClose();
  };

  const handlePopupButtonClick = () => {
    const hasUnsavedChanges = medicationDetails.some((medication, i) => {
      return JSON.stringify(medication) !== JSON.stringify(initialValues) && i === index;
    });
    if (hasUnsavedChanges) {
      handleOpenUnsavedChangesModal();
    } else {
      if (medicationDetails[index]?.newMedication) {
        setMedicationDetails(prevDetails => {
          const updatedDetails = [...prevDetails];
          updatedDetails.splice(index, 1);
          return updatedDetails;
        })
      }
      setInitialValues(null)
      onClose();
    }
  };

  const { skip } = useFetchWithMsal();
  const { data: prescriber } = useGetClientMedicationPrescriberQuery("", { skip });
  const [formErrors, setFormErrors] = useState({});
  const validateForm = () => {
    const errors = {};
    if (!medicationDetails[index].medicationName) {
      errors.medicationName = true;
    }
    if (!medicationDetails[index].medicationDose) {
      errors.medicationDose = true;
    }
    if (!medicationDetails[index].quantityPrescribed) {
      errors.quantityPrescribed = true;
    }
    if (!medicationDetails[index].prescriptionDate) {
      errors.prescriptionDate = true;
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      setInitialValues(null);
      onSave();
      onClose();
    }
  };

  const getSelectData = (name) => {
    switch (name) {
      case "prescriberId":
        return prescriber;
      default:
        return [];
    }
  };

  const handleRemoveMedication = (index) => {
    setMedicationIndexToDelete(index);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    if (medicationIndexToDelete !== null) {
      setMedicationDetails(prevDetails => {
        const updatedMedicationDetails = [...prevDetails];
        updatedMedicationDetails.splice(medicationIndexToDelete, 1);
        return updatedMedicationDetails;
      });
      setInitialValues(null)
      onClose();
      setConfirmDeleteOpen(false);
    }
  };

  const cancelDelete = () => {
    setMedicationIndexToDelete(null);
    setConfirmDeleteOpen(false);
  };

  const handleRemoveAdministrationInstructions = (medicationIndex, instructionIndex, medication) => {
    if (medication?.administrationInstructions.length > 1) {
      setMedicationDetails(prevDetails => {
        const updatedDetails = prevDetails.map((medication, index) => {
          if (index === medicationIndex && medication.administrationInstructions) {
            const updatedInstructions = medication.administrationInstructions.filter((_, i) => i !== instructionIndex);
            return { ...medication, administrationInstructions: updatedInstructions };
          }
          return medication;
        });
        return updatedDetails;
      });
    }
  };

  const getInputComponent = (item, index, width, height) => {
    if (medicationDetails[index] !== undefined) {
      switch (item?.type) {
        case "select":
          return (
            <SelectField
              name={item.name}
              label={item.label}
              data={getSelectData(item.name)}
              value={medicationDetails[index][item.name]}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              required={item.required}
              fullWidth
            />
          );
        case "date":
          return (
            <DatePickerField
              name={item.name}
              label={item.label}
              value={medicationDetails[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              disabled={
                ((item.name === 'earliestRefillDate' || item.name === 'refillsUntil') && !medicationDetails[index]['totalRefillsAllowed']) ||
                ((item.name === 'discontinueDate') && medicationDetails[index]['continueStatus'] !== 2)
              }
              error={formErrors[item?.name]}
            />
          );
        case "checkbox":
          return (
            <CheckboxField
              name={item.name}
              label={item.label}
              value={medicationDetails[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
            />
          );
        case "radio":
          return (
            <RadioGroup
              name={item.name}
              label={item.label}
              items={[{ id: 1, name: 'Continue' }, { id: 2, name: 'Discontinue' }]}
              value={medicationDetails[index][item.name]}
              required={item.required}
              setValue={(name, value) => handleFieldChange(name, value, index)}
            />
          );
        case "number":
          return (
            <InputField
              name={item?.name}
              label={item?.label}
              value={medicationDetails[index][item?.name]}
              setValue={(name, value) => item.maxLength ?
                value.length <= item.maxLength && handleFieldChange(name, Number(value), index) :
                handleFieldChange(name, Number(value), index)
              }
              fullWidth
              width={width}
              height={height}
              required={item.required}
              type='number'
              maxLength={item.maxLength}
              step={item.step}
              error={formErrors[item?.name]}
            />
          );
        default:
          return (
            <InputField
              name={item?.name}
              label={item?.label}
              value={medicationDetails[index][item?.name]}
              setValue={(name, value) => handleFieldChange(name, value, index)}
              fullWidth
              width={width}
              height={height}
              required={item.required}
              maxLength={item.maxLength}
              error={formErrors[item?.name]}
            />
          );
      }
    }
  };

  const handleFieldChange = (fieldName, value, medicationIndex) => {
    setMedicationDetails(prevDetails => {
      const updatedDetails = [...prevDetails];
      updatedDetails[medicationIndex] = { ...updatedDetails[medicationIndex], [fieldName]: value };
      return updatedDetails;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { width: '100%', maxWidth: "900px" } }}
      //BackdropProps={{ style: { backgroundColor: 'transparent' } }}

      sx={{
        position: 'absolute',
        maxWidth: '100%',
        /*  top: '40%',
         left: '50%',
         transform: 'translate(-50%, -50%)',
         width: '50%', // Set the width to 50% of the window width */

        //bgcolor: 'background.paper',
        //boxShadow: 24,
        //p: 4,
        //maxHeight: '90%', // Optional: To ensure it doesn't overflow the screen height
        //overflowY: 'auto', // Optional: Scroll if content overflows
      }}
    >

      <MedicationFragment key={index}>
        <MedicationHeader>
          <Box>
            {medicationDetails[index]?.newMedication ?
              <Typography variant="h2">New Medication</Typography> :
              <Typography variant="h2">Medication {index + 1}</Typography>}
          </Box>
          <PopupButton aria-label="close" onClick={handlePopupButtonClick}>
            <CloseIcon />
          </PopupButton>
        </MedicationHeader>
        <MedicationBody>
          <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: "0px solid",
                  borderColor: "primary.main",
                  margin: "20px 0 10px 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Medication sx={{ marginRight: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Medication Details
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              {getInputComponent({ name: "medicationName", label: "Medication Name", type: "text", required: true, maxLength: 128 }, index)}
            </Grid>
            <Grid item xs={3}>
              {getInputComponent({ name: "medicationDose", label: "Medication Dose", type: "text", required: true, maxLength: 32 }, index)}
            </Grid>
            <Grid item xs={3}>
              {getInputComponent({ name: "quantityPrescribed", label: "Quantity Prescribed", type: "number", required: true, step: "0.01", maxLength: 8 }, index)}
            </Grid>
            <Grid item xs={3}>
              {getInputComponent({ name: "medicationEndDate", label: "Medication End Date", type: "date" }, index)}
            </Grid>
          </Grid>
          <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderBottom: "0px solid",
                  borderColor: "primary.main",
                  margin: "20px 0 10px 0",
                }}
              >
                <Typography variant="body1">Refills</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "totalRefillsAllowed", label: "Total Refills Allowed", type: "number", maxLength: 8 }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "earliestRefillDate", label: "Earliest Refill Date", type: "date" }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "refillsUntil", label: "Refills Until Date", type: "date" }, index)}
            </Grid>
          </Grid>
          <Grid container rowSpacing={"20px"} sx={{ margin: "20px 0 10px 0" }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: "1px solid",
                  borderColor: "primary.main",
                  margin: "20px 0 10px 0",
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <Assignment sx={{ marginRight: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Administration Instructions
                </Typography>
              </Box>
            </Grid>
            {selectedAdministrationInstructionsIndex !== null ? <AdministrationInstructionsField
              key={selectedAdministrationInstructionsIndex}
              instructionIndex={selectedAdministrationInstructionsIndex}
              medication={medicationDetails[index]}
              fields={medicationDetails[index].administrationInstructions[selectedAdministrationInstructionsIndex]}
              index={index}
              getInputComponent={getInputComponent}
              setMedicationDetails={setMedicationDetails}
              isLastItem={medicationDetails[index]?.administrationInstructions?.length === 1}
              setSelectedAdministrationInstructionsIndex={setSelectedAdministrationInstructionsIndex}
              onDelete={() => handleRemoveAdministrationInstructions(index, selectedAdministrationInstructionsIndex, medicationDetails[index])}
              doseType={doseType}
              frequency={frequency}
              method={method}
            /> :
              <AdministrationInstructionsGrid
                medicationIndex={index}
                medication={medicationDetails[index]}
                values={medicationDetails[index]?.administrationInstructions}
                setSelectedAdministrationInstructionsIndex={setSelectedAdministrationInstructionsIndex}
                onDelete={handleRemoveAdministrationInstructions}
                doseType={doseType}
                frequency={frequency}
                method={method}
              />
            }
            {selectedAdministrationInstructionsIndex === null && <Grid container xs={12} sx={{ zIndex: 1 }}>
              <AddNewButton onClick={() => handleAddAdministrationInstructions(index, medicationDetails[index].administrationInstructions?.length)}>
                <img src={roundedPlus} />add new
              </AddNewButton>
            </Grid>}
          </Grid>
          <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: "1px solid",
                  borderColor: "primary.main",
                  margin: "20px 0 10px 0",
                  display: "flex",
                  alignItems: "center",
                  height: "40px",
                }}
              >
                <LocalHospital sx={{ marginRight: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Prescriber Details
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "prescriberId", label: "Prescriber", type: "select" }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "prescriberPhone", label: "Prescriber Phone", type: "text", maxLength: 32 }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "pharmacy", label: "Pharmacy", type: "text", maxLength: 16 }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "pharmacyPhone", label: "Pharmacy Phone", type: "text", maxLength: 32 }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "medicalReasonReasonforOrder", label: "Medical Reason For Order", type: "text", maxLength: 64 }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "prescriptionDate", label: "Prescription Date", type: "date", required: true }, index)}
            </Grid>
          </Grid>
          <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderTop: "1px solid",
                  borderColor: "primary.main",
                  margin: "20px 0 10px 0",
                  display: "flex",
                  height: "40px",
                  alignItems: "center",
                }}
              >
                <Info sx={{ marginRight: "10px" }} />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  Status
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "continueStatus", label: "Continue/Discontinue", type: "radio", required: true }, index)}
            </Grid>
            <Grid item xs={4}>
              {getInputComponent({ name: "discontinueDate", label: "Discontinue Date", type: "date" }, index)}
            </Grid>
          </Grid>
        </MedicationBody>
        <PopupFooter>
          <Button sx={{ marginRight: "10px", width: "108px", height: '26px', borderRadius: '2px' }} variant="contained" onClick={handleSave}>Save</Button>
          <Button sx={{ marginRight: "10px", width: "108px", height: '26px', borderRadius: '2px' }} variant="outlined" onClick={handlePopupButtonClick}>Close</Button>
          <Delete sx={{ marginLeft: "auto", cursor: "pointer", width: "14px", }} onClick={() => handleRemoveMedication(index)} />
          <Modal open={confirmDeleteOpen} onClose={cancelDelete}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
              <Typography variant="h6">Are you sure you want to delete this medication?</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button
                  sx={{
                    marginRight: "10px",
                    width: "108px",
                    height: '26px',
                    borderRadius: '2px'
                  }} variant="outlined"
                  onClick={cancelDelete}>
                  Cancel
                </Button>
                <Button
                  sx={{
                    marginRight: "10px",
                    width: "108px",
                    height: '26px',
                    borderRadius: '2px'
                  }} variant="contained"
                  onClick={confirmDelete}>
                  Delete
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={unsavedChangesModalOpen} onClose={handleCloseUnsavedChangesModal}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
              <Typography variant="h6">Your changes will not be saved</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button
                  sx={{
                    marginRight: "10px",
                    width: "108px",
                    height: '26px',
                    borderRadius: '2px'
                  }}
                  variant="outlined"
                  onClick={handleCloseUnsavedChangesModal}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    marginRight: "10px",
                    width: "108px",
                    height: '26px',
                    borderRadius: '2px'
                  }}
                  variant="contained"
                  onClick={handleCancel}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </PopupFooter>
      </MedicationFragment>
    </Dialog>
  );
};

