import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";

const AutocompleteFieldForFilters = (props) => {
  const {
    errorText,
    label,
    data = [], // Default to an empty array if data is undefined
    isError,
    width,
    value,
    onChange,
    disabled,
    labelType,
    marginBottom,
    ...rest
  } = props;

  console.log("AutocompleteField data:", data);

  // Create a unique version of the data array using IDs to eliminate duplicates.
  const uniqueData = data.filter(
    (item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
  );

  return (
    <Box sx={{ textAlign: 'start', marginBottom: marginBottom || 0 }}>
      {label ? (
        <InputLabel id={label}>
          <Typography variant={labelType || "body1"}>{label}</Typography>
        </InputLabel>
      ) : null}
      <FormControl {...rest} error={isError} style={{ width: '100%' }}>
        <Autocomplete
          disablePortal={false}
          id="autocomplete-field"
          options={uniqueData}
          getOptionLabel={(option) => option?.name || ""}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option?.name?.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          value={value || null}
          disabled={disabled}
          onChange={(event, newValue) => onChange(newValue || null)}
          sx={{ maxWidth: "290px", width: '100%' }}
          renderOption={(props, option) => (
            <Box component="li" {...props} key={option.id} sx={{ display: 'flex', alignItems: 'left' }}>
              <Typography variant="body1">{option.name || ""}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={isError}
              helperText={isError ? errorText : null}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default AutocompleteFieldForFilters;
