import React from "react";
import "./BilingReport.css";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BilingReport } from "../../components/bilingReport/BilingReport";

export const BillingReportPage = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <AuthenticatedTemplate>
      {activeAccount ? (
        <div className="clientContainer container">
          <BilingReport fromSource="billing"/>
        </div>
      ) : null}
    </AuthenticatedTemplate>
  );
};
