import { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { Button, Link, Typography } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {
  ClientsTitleContainer,
  CrossImg,
  EmptyMessage,
  FilterChip,
  FilterChipList,
  HeaderContainer,
  InsuredsContainer,
  InsuredsType,
  NoteContainer,
  NoteDate,
  NoteHeader,
  NoteName,
  NoteText,
  NotesCardStatus,
  RowOptionContainer,
  SearchInput,
  SearchInputContainer,
  ServiceButtons,
  ServiceContainer,
  ServiceDate,
  ServiceHeader,
  ServiceName,
  ServiceStatus,
  StatisticsOptions,
  StatusChip,
  TableControlsContainer,
  TableSearchContainer,
  TableRowContainer,
  ToolsImg,
  ToolsImgWhite,
  Img,
  ImgContainer,
  statusStyles,
  AddNoteIcon,
} from './TableTab.styled';
import {
  AddItemContainer,
  AddItemOption,
  AddItemOptionsContainer,
  CalendarContainer,
  CalendarHeader,
  CalendarToolbar,
  CalendarToolsContainer,
  CheckboxContainer,
  CheckboxSubItems,
  DatePickerContainer,
  DateRangeContainer,
  EventClient,
  EventContainer,
  EventContent,
  EventFooter,
  EventHeader,
  EventMaterials,
  EventName,
  EventRequestSatatus,
  EventStatus,
  FilterGroup,
  PopupBody,
  PopupButton,
  RangePickerContainer,
  RangePickerItem,
  ShowMoreButton,
} from "../calendar/Calendar.styled";
import { NoteAddRounded, Search, StackedLineChart, WarningAmber } from '@mui/icons-material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { TableFilter } from './tableFilter/TableFilter';
import { useGetClientsMutation, useGetNotesTypesQuery, useGetProfilePictureQuery, useGetAssignmentClientsMutation, useEditNoteMutation, usePostNoteMutation, useCreateClientServiceMutation, useUpdateClientServiceMutation, useGetClientServiceByServiceIdQuery } from '../../services/ClientService';
import styled from '@emotion/styled';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingPanel } from '../../shared/components/LoadingPanel';
import { useGetClientCounselorsQuery, useGetClientHousingsQuery, useGetClientStatusesQuery, useGetCompanyBranchesQuery, useGetTracksQuery, useGetLevelOfCareQuery } from '../../services/UtilityService';
import addClientSvg from "../../shared/icons/addClient.svg";
import crossSvg from "../../shared/icons/cross.svg";
import renewFilter from "../../shared/icons/renewFilter.svg";
import list from "../../shared/icons/list.svg";
import exportIcon from "../../shared/icons/export.svg";
import calendar from "../../shared/icons/calendar.svg";
import statistics from "../../shared/icons/statistics.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import addNote from "../../shared/icons/addNote.svg";
import { setStatus, setCounselor, setHousing, setLevelofCare } from '../../store/slices/FilterSlice';
import useFetchWithMsal from '../../utils/useFetchWithMsal';
import { NotesPanel } from "./notesPanel/NotesPanel";
import { CalendarComponent } from "../calendar/Calendar";
import CalendarModalWrapper from "../calendar/CalendarModalWrapper";
import { ServicesStatisticsPopup } from "./servicesStatisticsPopup/ServicesStatisticsPopup";
import { NotesGridField } from "./notesPanel/NotesGridField";
import { AddServicesPopup } from "../calendar/addServicesPopup/AddServicesPopup";
import { AddNotePopup } from "../calendar/addServicesPopup/AddNotePopup";
import { MedicalServicesRounded, CheckCircle, CloseRounded, HourglassBottomRounded, DeleteOutline, AdminPanelSettings, Eject, Block, HourglassEmpty, History, HelpOutline, SentimentDissatisfied, PersonAdd, } from '@mui/icons-material';
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";

const StyledPaper = styled(Paper)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}));

const StyledTableHead = styled(TableHead)(() => ({
  th: {
    whiteSpace: 'nowrap',
    background: '#fff',
    color: '#4281AB',
    paddingTop: '6px',
    paddingBottom: '8px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '600',
    borderBottom: "1px solid #b2cede",
  },
  '&::after': {
    height: '8px',
    display: 'table-row',
    content: '""'
  }
}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    color: '#000',
    paddingTop: '15px',
    paddingBottom: '15px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
  },
  th: {
    paddingTop: '15px',
    paddingBottom: '15px',
    minWidth: '120px',
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: '400',
    a: {
      color: theme.palette.background.main,
      textDecoration: 'none',
    }
  },
  tr: {
    background: '#fff',
    boxShadow: '0px 1px 2px 0px #00000040',
  }
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  padding: '5px 18px',
  fontFamily: 'Open Sans',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  marginRight: '13px',
}));

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiInputBase-root': {
    background: theme.palette.background.mainBlue
  }
}));

function stableSort(array) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: false,
    label: 'Client',
  },
  {
    id: 'statusName',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'insuredsGroup',
    numeric: false,
    disablePadding: false,
    label: 'Insurance',
  },
  {
    id: 'counselorFirstName',
    numeric: false,
    disablePadding: false,
    label: 'Counselor',
  },
  {
    id: 'housing',
    numeric: false,
    disablePadding: false,
    label: 'Housing',
  },
  {
    id: 'cellPhone',
    numeric: false,
    disablePadding: false,
    label: 'Client Phone Number',
  },
  {
    id: 'services',
    numeric: false,
    disablePadding: false,
    label: 'Services',
  },
  {
    id: 'Notes',
    numeric: false,
    disablePadding: false,
    label: 'Notes & Alerts',
  },
];




function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export const TableTab = () => {
  const dispatch = useDispatch();
  const { skip } = useFetchWithMsal();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [counselorFilter, setCounselorFilter] = useState(null);
  const [housingFilter, setHousingFilter] = useState(null);
  const [levelofcareFilter, setLevelofCareFilter] = useState(null);
  const [openNotesPanel, setOpenNotesPanel] = useState(false);
  const [notes, setNotes] = useState([]);
  const [clientId, setClientId] = useState(null);
  const [clientName, setClientName] = useState('');
  const [clientsType, setClientsType] = useState('assigned');
  const initialStartDate = dayjs((new Date().setMonth(new Date().getMonth())));
  const initialEndDate = dayjs((new Date().setMonth(new Date().getMonth())));
  const [dateFrom, setDateFrom] = useState(initialStartDate);
  const [dateTo, setDateTo] = useState(initialEndDate);

  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [serviceCalendarOpen, setServiceCalendarOpen] = useState(false);
  const [notesCalendarOpen, setNotesCalendarOpen] = useState(false);

  const [servicesStatisticsOpen, setServicesStatisticsOpen] = useState(false);

  const [isAddItemDropdownVisible, setIsAddItemDropdownVisible] = useState(false);
  const [isShowAddServicePopup, setIsShowAddServicePopup] = useState(false);
  const [isShowEditNotePopup, setIsShowEditNotePopup] = useState(false);
  const [editNote, { isLoading: isEditNodeLoading }] = useEditNoteMutation();
  const [postNote, { isLoading: isPostNodeLoading }] = usePostNoteMutation();
  const [addedNote, setAddedNote] = useState(null);

  const [addedService, setAddedService] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [serviceClickKey, setServiceClickKey] = useState(0);
  const [changebleEvent, setChangebleEvent] = useState(null);
  const [pendingData, setPendingData] = useState(null);
  const [pendingType, setPendingType] = useState('');
  const [postClientService, { isLoading: isEditServiceLoading }] = useCreateClientServiceMutation();
  const [updateClientService, { isLoading: isPostServiceLoading }] = useUpdateClientServiceMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');


  const { status, counselor, housing, levelofcare } = useSelector(
    (state) => state.filter
  );

  const { data: statues } = useGetClientStatusesQuery('', { skip });
  const { data: counselors } = useGetClientCounselorsQuery('', { skip });
  const { data: housings } = useGetClientHousingsQuery('', { skip });
  const { data: types } = useGetNotesTypesQuery({ skip });
  const { data: levelofcares } = useGetLevelOfCareQuery('', { skip });


  const [getAssignmentClients, { data: assignmentClients }] = useGetAssignmentClientsMutation({
    isShowAll: clientsType === 'all' ? true : false
  }, { skip })

  const [getClients, { data, isLoading }] = useGetClientsMutation({
    recordNumber: page * rowsPerPage,
    offset: rowsPerPage,
    isShowAll: clientsType === 'all' ? true : false
  }, { skip })


  const handleClientsTypeChange = (event, type) => {
    setClientsType(type);
  };

  useEffect(() => {
    getAssignmentClients({
      startDate: null,
      endDate: null,
      isShowAll: clientsType === 'all' ? true : false
    });
  }, [dateFrom, dateTo, clientsType]);

  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");

  let originalRows = data?.clients

  useEffect(() => {
    if (originalRows) {
      setRows(originalRows)
    }
    if (data?.totalCount) {
      setCount(data?.totalCount)
    }
  }, [data, originalRows]);

  const ProfilePictureCell = ({ clientId }) => {
    const { data: pictureUrl, error, isLoading } = useGetProfilePictureQuery(clientId);

    if (isLoading) {
      return (
        <Box sx={{ position: "relative", width: "100px", height: "100px" }}>
          <Skeleton
            variant="circular"
            width={100}
            height={100}
            animation="wave"
            sx={{ margin: "0 auto" }}
          />
        </Box>
      );
    }

    if (error) {
      return (
        <Box sx={{ position: "relative", width: "100px", height: "100px", textAlign: "center" }}>
          <Typography variant="body2" color="error">
            Error
          </Typography>
        </Box>
      );
    }

    return (
      <ImgContainer>
        <img
          src={pictureUrl}
          alt="Client Profile"
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      </ImgContainer>
    );
  };



  useEffect(() => {
    onApplyRowFilter(counselorFilter, housingFilter)
  }, [counselorFilter, housingFilter]);

  const replaceName = (name) => {
    if (name === 'firstName') {
      return 'fullName';
    } else if (name === 'counselorFirstName') {
      return 'counselorFullName';
    } else {
      return name;
    }
  }

  useEffect(() => {
    if (!skip) {
      const transformedOrderBy = replaceName(orderBy)
      getClients({
        recordNumber: page * rowsPerPage,
        offset: rowsPerPage,
        status,
        counselor,
        housing,
        levelofcare,
        order,
        orderBy: transformedOrderBy
      })
    }
  }, [page, rowsPerPage, order, orderBy, skip]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // Rename the returned variables to avoid conflict
  const {
    data: serviceData,
    error: serviceError,
    isLoading: isServiceLoading,
  } = useGetClientServiceByServiceIdQuery(selectedServiceId, {
    skip: !selectedServiceId || selectedServiceId === 0, // Skip if no serviceId is selected
  });

  const RecentServicesOnClick = (event) => {
    console.log('RecentServicesOnClick event:', event);
    // Reset selectedServiceId to null before setting it to the new value to allow reselecting the same service
    setSelectedServiceId(event.id);
    setServiceClickKey((prevKey) => prevKey + 1); // Update the click key to force useEffect to run
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (serviceData) {
      console.log('RecentServicesOnClick serviceData:', serviceData);
      console.log('RecentServicesOnClick selectedServiceId:', selectedServiceId);
      console.log('RecentServicesOnClick form ULR:', serviceData.psychosocialAssessmentForm);
      // Set client name and client ID with fallback to empty strings if undefined
      setClientName(`${serviceData.clientFirstName || ""} ${serviceData.clientLastName || ""}`);
      setClientId(serviceData.clientId || null);

      // Creating data to set with safeguards for each field
      const dataToSet = {
        startDate: serviceData.startDate ? new Date(serviceData.startDate).toISOString() : null,
        endDate: serviceData.endDate ? new Date(serviceData.endDate).toISOString() : null,
        date: serviceData.startDate ? new Date(serviceData.startDate).toISOString() : null,
        description: serviceData.description || "",
        materials: serviceData.materials || "",
        id: serviceData.id || null,
        serviceId: serviceData.serviceId || null,
        clientId: serviceData.clientId || null,
        serviceGroupNameId: serviceData.serviceGroupNameId || null,
        serviceTopicId: serviceData.serviceTopicId || null,
        serviceRoomId: serviceData.serviceRoomId || null,
        attendanceStatus: serviceData.attendanceStatus || "",
        trackId: serviceData.trackId || null,
        note: serviceData.note || "",
        psychosocialAssessmentForm: serviceData.psychosocialAssessmentForm || "",
      };

      // Open the link in a new tab
      if (serviceData?.psychosocialAssessmentForm) {
        window.open(`#/${serviceData?.psychosocialAssessmentForm}`, '_blank');
      }

      //navigate(`/${serviceData?.psychosocialAssessmentForm}`);
      //window.location.reload();
      //setChangebleEvent(dataToSet);
      //setIsShowAddServicePopup(true);
    }
  }, [serviceData, serviceClickKey]);

  const handleOpenNotes = (notes, id) => {
    setOpenNotesPanel(true);
    setClientId(id)
    setNotes(notes)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      stableSort(rows).slice(),
    [order, orderBy, page, rowsPerPage, rows, data, originalRows],
  );

  const requestSearch = (searchVal) => {
    setSearched(searchVal);
    getClients({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      status,
      counselor,
      housing,
      levelofcare,
      searchVal
    })
  };

  const onApplyFilters = (resetFilter) => {
    console.log('ApplyFilters counselours', counselors);
    getClients({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      status: resetFilter === 'status' ? '' : status,
      counselor: resetFilter === 'counselor' ? '' : counselor,
      housing: resetFilter === 'housing' ? '' : housing,
      levelofcare: resetFilter === 'levelofcare' ? '' : levelofcare,
    })
  };

  const onApplyRowFilter = (counselorFilter, housingFilter) => {
    getClients({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
      counselor: counselorFilter,
      housing: housingFilter,
    })
  };

  const onHousingRowFilterClick = (housingId) => {
    dispatch(setHousing(housingId))
    onApplyRowFilter('', housingId)
  }

  const onCounselorRowFilterClick = (counselorId) => {
    dispatch(setCounselor(counselorId))
    onApplyRowFilter(counselorId, '')
  }

  const onResetFilters = () => {
    getClients({
      recordNumber: page * rowsPerPage,
      offset: rowsPerPage,
    })
  };

  const onResetStatus = () => {
    dispatch(setStatus(""));
    onApplyFilters('status')
  };

  const onResetCounselor = () => {
    dispatch(setCounselor(""));
    onApplyFilters('counselor')
  };

  const onResetHousing = () => {
    dispatch(setHousing(""));
    onApplyFilters('housing')
  };

  const onResetLevelofCare = () => {
    dispatch(setLevelofCare(""));
    onApplyFilters('levelofcare')
  };

  const openServiceCalendar = (userId, userName) => {
    setServiceCalendarOpen(!serviceCalendarOpen)
    setSelectedUserId(userId)
    setSelectedUserName(userName)
  }

  const openNotesCalendar = (userId, userName) => {
    setNotesCalendarOpen(!notesCalendarOpen)
    setSelectedUserId(userId)
    setSelectedUserName(userName)
  }

  const closeServiceCalendar = () => {
    setServiceCalendarOpen(!serviceCalendarOpen)
  }

  const closeNotesCalendar = () => {
    setNotesCalendarOpen(!notesCalendarOpen)
  }

  const openStatistics = (userId, userName) => {
    setServicesStatisticsOpen(!servicesStatisticsOpen)
    setSelectedUserId(userId)
    setSelectedUserName(userName)
  }

  const closeStatistics = () => {
    setServicesStatisticsOpen(!servicesStatisticsOpen)
  }

  useEffect(() => {
    setIsAddItemDropdownVisible(false);
  }, [isShowAddServicePopup, isShowEditNotePopup]);

  const onPopupClose = () => {
    setIsShowAddServicePopup(false);
    setIsShowEditNotePopup(false);

    setSelectedServiceId(0);
    setChangebleEvent(null);
  };

  const onCreateNote = (data, type) => {
    if (type === "change") {
      editNote({
        data: {
          text: data.text,
          clientId: clientId,
          quickNoteTypeId: data.quickNoteTypeId,
          ownerId: data.ownerId,
          quickNoteRequestSatatusId: data.requestStatus || null,
        }, noteId: data.noteId
      }).then(() => setAddedNote(data));
    } else {
      postNote({
        text: data.text,
        clientId: clientId ? clientId : data.client,
        quickNoteTypeId: data.quickNoteTypeId,
        quickNoteRequestSatatusId: data.requestStatus || null,
      }).then(() => setAddedNote(data));
    }

    onPopupClose();
  };

  const onCreateServiceWrapper = (data, type) => {
    setPendingData(data);
    setPendingType(type);
    onCreateService(data, type);
  };

  const extractIds = array => {
    return array.map(item => item.id);
  }

  const onCreateService = (data, type) => {
    data.clients = data?.clientId?.length > 1 ? extractIds(data.clientId) : null;
    data.clientId = clientId ? clientId : data.clientId[0]?.id || data.clientId;
    data.isGroup = data?.clients?.length > 1 ? true : false;
    data.serviceRoomId = data?.serviceRoomId?.id;

    const handleResponse = (response) => {
      if (response?.data?.isConflict) {
        setModalMessage(response.data?.message);
        setModalOpen(true);
      } else {
        setAddedService(data);
        onPopupClose();
      }
    };

    if (type === "change") {
      updateClientService(data).then(handleResponse);
    } else {
      postClientService({ clientId, data }).then(handleResponse);
    }
  };


  return (
    <Box sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px" }}>
      {isLoading && <LoadingPanel />}
      <StyledPaper sx={{ width: '100%', mb: 2 }}>
        <HeaderContainer>
          <TableSearchContainer>
            <ClientsTitleContainer>
              Clients
            </ClientsTitleContainer>
            <SearchInputContainer>
              <Search />
              <SearchInput
                value={searched}
                onChange={e => requestSearch(e.target.value)}
                placeholder='Search'
              />
            </SearchInputContainer>
            <TableFilter onApplyFilters={onApplyFilters} onResetFilters={onResetFilters} />
          </TableSearchContainer>
          <TableControlsContainer>
            <CustomButton
              variant="outlined"
              startIcon={<MedicalServicesRounded />}
              onClick={() => setIsShowAddServicePopup(true)}
            >
              Create Service
            </CustomButton>
            <CustomButton
              variant="outlined"
              startIcon={<NoteAddRounded />}
              onClick={() => setIsShowEditNotePopup(true)}
            >
              Add Note
            </CustomButton>
            <CustomButton
              variant="outlined"
              startIcon={<PersonAdd />}
              onClick={(e) => {
                window.location.href = '#/createClient';
              }}
            >
              Add Client
            </CustomButton>
          </TableControlsContainer>
        </HeaderContainer>
        <TableContainer>
          <FilterChipList>
            {statues && status ?
              <FilterChip>
                <span>Status: </span>{statues[status - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetStatus} />
              </FilterChip> : null
            }
            {counselors && counselor ? (
              <FilterChip>
                <span>Counselor: </span>
                {counselors.find((c) => c.id === counselor)?.name || "Unknown"}
                <CrossImg src={crossSvg} alt="" onClick={onResetCounselor} />
              </FilterChip>
            ) : null}
            {housings && housing ?
              <FilterChip>
                <span>Housing: </span>{housings[housing - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetHousing} />
              </FilterChip> : null
            }
            {levelofcares && levelofcare ?
              <FilterChip>
                <span>Level of Care: </span>{levelofcares[levelofcare - 1]?.name}
                <CrossImg src={crossSvg} alt="" onClick={onResetLevelofCare} />
              </FilterChip> : null
            }
          </FilterChipList>

          <Table
            sx={{
              minWidth: 750,
              minHeight: 110,
              borderCollapse: 'separate',
              borderSpacing: '0 7px',
              padding: '5px',
            }}
            aria-labelledby="tableTitle"
            size={'medium'}

          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              colSpan={6}
            />
            {visibleRows.length ? <StyledTableBody>
              {visibleRows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: 'pointer', verticalAlign: 'top' }}
                    padding='normal'
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding='normal'
                    >
                      {/* {row.profilePicture && (
                        <ImgContainer>
                          <Img
                            alt="clientPhoto"
                            src={`data:${row.profilePicture.mimeType};base64,${row.profilePicture.data}`}
                          />
                        </ImgContainer>
                      )} */}
                      <ImgContainer>
                        <ProfilePictureCell clientId={row.id} />
                      </ImgContainer>
                      <Link href={`#/client/${row.id}`}>{row.firstName} {row.lastName}</Link>

                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: 150 }}>
                      {row.statusName && (
                        <StatusChip statusName={row.statusName}>
                          {statusStyles[row.statusName]?.icon}
                          {row.statusName}
                        </StatusChip>
                      )}
                      {row.intakeDetails && (
                        <TableRowContainer>
                          {row.intakeDetails === "Intake Not Complete" ? (
                            <>
                              <WarningAmber fontSize="small" sx={{ color: "red" }} />
                              <Typography sx={{ paddingTop: "3px", paddingLeft: "2px", color: "red" }}>
                                {row.intakeDetails}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <PlayCircleOutlineIcon fontSize="small" />
                              <Typography sx={{ paddingTop: "3px", paddingLeft: "2px" }}>
                                {row.intakeDetails}
                              </Typography>
                            </>
                          )}
                        </TableRowContainer>
                      )}
                      {row.levelOfCareId && (
                        <TableRowContainer>
                          <StackedLineChart fontSize="small" />
                          <Typography sx={{ paddingTop: "3px", paddingLeft: "2px" }}>{row.levelOfCareName}</Typography>
                        </TableRowContainer>
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: 200 }}>
                      <InsuredsContainer>
                        <div> {row?.insurance?.name}</div>
                      </InsuredsContainer>
                      <InsuredsContainer>
                        <InsuredsType>Group #</InsuredsType>
                        <div> {row.insurance?.insuredsGroup}</div>
                      </InsuredsContainer>
                      <InsuredsContainer>
                        <InsuredsType>Insured's ID #</InsuredsType>
                        <div> {row.insurance?.insuredsIdNumber}</div>
                      </InsuredsContainer>

                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: 200 }}>
                      {row.counselorFirstName} {row.counselorLastName}
                      {(row.counselorFirstName || row.counselorLastName) && <RowOptionContainer
                        onClick={
                          () => onCounselorRowFilterClick(row.counselorId)
                        }
                      >
                        <ToolsImg src={renewFilter} alt="" />
                        View clients
                      </RowOptionContainer>}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: 200 }}>
                      {row.housingName}
                      <RowOptionContainer
                        onClick={
                          () => onHousingRowFilterClick(row.housingId)
                        }
                      >
                        <ToolsImg src={renewFilter} alt="" />
                        View clients
                      </RowOptionContainer>
                    </TableCell>
                    <TableCell align="left">
                      {row.primaryPhone}<br />
                      {row.cellPhone}
                    </TableCell>
                    <TableCell align="left" sx={{ minWidth: 200 }}>
                      {row?.service.map(service =>
                        <ServiceContainer key={service?.id}>
                          <ServiceHeader>
                            <ServiceDate>
                              {service?.startDate ? moment(service.startDate).format("MM/DD/YYYY") : ''}
                            </ServiceDate>
                            <ServiceStatus>
                              {service.status}
                            </ServiceStatus>
                          </ServiceHeader>
                          <ServiceButtons style={{ textAlign: 'left' }}>
                            <ServiceName>
                              <Link component="button" onClick={() => RecentServicesOnClick(service)} underline="hover">
                                {service.name}
                              </Link>
                            </ServiceName>
                          </ServiceButtons>
                        </ServiceContainer>
                      )}
                      <StatisticsOptions>
                        <RowOptionContainer onClick={() => openServiceCalendar(row.id, `${row.firstName} ${row.lastName}`)}>
                          <ToolsImg src={calendar} alt="" />
                          Calendar
                        </RowOptionContainer>
                        <RowOptionContainer onClick={() => openStatistics(row.id, `${row.firstName} ${row.lastName}`)}>
                          <ToolsImg src={statistics} alt="" />
                          Statistics
                        </RowOptionContainer>
                      </StatisticsOptions>
                    </TableCell>
                    <TableCell align="left">
                      <NotesGridField types={types} notes={row.quickNotes} />
                      <StatisticsOptions>
                        <RowOptionContainer onClick={() => handleOpenNotes(row.quickNotes, row.id)}>
                          <ToolsImg src={list} alt="" />
                          View all
                        </RowOptionContainer>
                        <RowOptionContainer onClick={() => openNotesCalendar(row.id, `${row.firstName} ${row.lastName}`)}>
                          <ToolsImg src={calendar} alt="" />
                          Calendar
                        </RowOptionContainer>
                      </StatisticsOptions>
                    </TableCell>
                  </TableRow>
                );
              })}
            </StyledTableBody> : <EmptyMessage>No data to show</EmptyMessage>}
          </Table>
        </TableContainer>
        <StyledPagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledPaper>
      {openNotesPanel && <NotesPanel setVisibility={setOpenNotesPanel} clientIdForm={clientId} userNotes={notes} />}
      {(serviceCalendarOpen || notesCalendarOpen) &&
        <CalendarModalWrapper
          type={notesCalendarOpen ? 'notes' : 'services'}
          open={notesCalendarOpen ? openNotesCalendar : openServiceCalendar}
          onClose={notesCalendarOpen ? closeNotesCalendar : closeServiceCalendar}
          clientIdForm={selectedUserId}
          selectedUserName={selectedUserName}
        />
      }
      <AddServicesPopup
        event={changebleEvent}
        clientName={null}
        open={isShowAddServicePopup}
        onClose={onPopupClose}
        onClick={onCreateServiceWrapper}
        clientId={null}
        clients={assignmentClients}
        onClientsTypeChange={handleClientsTypeChange}
        clientsType={clientsType}
      />
      <AddNotePopup
        event={null}
        clientName={clientName}
        open={isShowEditNotePopup}
        onClose={onPopupClose}
        onClick={onCreateNote}
        clientId={null}
        clients={assignmentClients}
      />
      <ServicesStatisticsPopup clientName={selectedUserName} clientId={selectedUserId} open={servicesStatisticsOpen} onClose={closeStatistics} />
    </Box>
  );
}