import React, { useState } from "react";
import { Modal, Box, Button, MenuItem, List, ListItem, Menu, IconButton, ListItemText, ListItemIcon } from '@mui/material';
import { Settings as SettingsIcon, WorkspacesSharp } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import BuildIcon from '@mui/icons-material/Build';
import styled from "styled-components";
import dashboardSvg from "../../shared/icons/sidebarMenu/dashboard.svg";
import callCenterSvg from "../../shared/icons/sidebarMenu/callCenter.svg";
import clientsSvg from "../../shared/icons/sidebarMenu/clients.svg";
import employeesSvg from "../../shared/icons/sidebarMenu/employees.svg";
import appointmentsSvg from "../../shared/icons/sidebarMenu/appointments.svg";
import payrollSvg from "../../shared/icons/sidebarMenu/payroll.svg";
import meetingSvg from "../../shared/icons/sidebarMenu/meeting.svg";
import referralSvg from "../../shared/icons/sidebarMenu/referral.svg";
import historySvg from "../../shared/icons/sidebarMenu/history.svg";
import helpSvg from "../../shared/icons/sidebarMenu/help.svg";
import { Link, useLocation } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import UserGrid from "../userInfo/UserGrid";
import { useNavigate } from 'react-router-dom';


export const SidebarMenuTitle = styled.div`
  color: #fff;
  font-family: Open Sans;
  font-size: 11px;
  font-weight: 400;
  transition: display 0.3s ease-in-out;
`;

const SidebarMenuContainer = styled.div`
  background: #BD986A;
  top: 70px;
  height: 100%;
  z-index: 999;
  position: fixed;
  height: calc(100% - 70px);
  transition: box-shadow 0.3s ease-in-out; /* Transition for the box-shadow */
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Adding shadow when hovered */
  }
  &:hover ${SidebarMenuTitle} {
    display: block;
  }
  ${SidebarMenuTitle} {
    opacity: 0;
    max-height: 0;
    max-width: 0;
    overflow: hidden;
    font-size: 10pt;
    transition: opacity 0.3s ease-in-out, max-height 0.3s ease-in-out; /* Animation for opacity and max-height */
  }
  &:hover ${SidebarMenuTitle} {
    opacity: 1;
    margin-left: 17px;
    max-height: 200px;
    max-width: 200px;
  }
`;


const SidebarMenuItem = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  height: 36px;
  padding: 0 15px;
  cursor: pointer;
  background: ${props => (props.active ? "#846a4a" : "transparent")};
  &:hover {
    background: #846a4a;
  }
  text-decoration: none;
`;

export const ImgContainer = styled.div`
  width: 24px;
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const SidebarMenu = () => {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showUserGrid, setShowUserGrid] = useState(false);
  const settingsOpen = Boolean(anchorEl);

  const handleSettingsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const handleUsersClick = () => {
    setShowUserGrid(true);
    handleSettingsClose();
  };

  const handleCloseUserGrid = () => {
    setShowUserGrid(false);
  };

  const navigate = useNavigate();

  const handleMenuItemClick = (path) => {
    navigate(path);
    handleSettingsClose();
  };


  return (
    
      <SidebarMenuContainer style={{zIndex: `${location.pathname.includes("calendar") ? 9999 : 999}`}}>
        <AuthenticatedTemplate>
          <StyledLink to="/home">
            <SidebarMenuItem active={(location.pathname.includes("client") || location.pathname === "/home")}>
              <ImgContainer>
                <img src={clientsSvg} alt=""/>
              </ImgContainer>
              <SidebarMenuTitle>Clients</SidebarMenuTitle>
            </SidebarMenuItem>
          </StyledLink>
          <StyledLink to="/bilingReport">
            <SidebarMenuItem active={(location.pathname.includes("bilingReport") || location.pathname === "/bilingReport")}>
              <ImgContainer>
                <img src={payrollSvg} alt=""/>
              </ImgContainer>
              <SidebarMenuTitle>Billing</SidebarMenuTitle>
            </SidebarMenuItem>
          </StyledLink>
          <StyledLink to="/authorizationReport">
            <SidebarMenuItem active={(location.pathname.includes("authorizationReport") || location.pathname === "/authorizationReport")}>
              <ImgContainer>
                <img src={referralSvg} alt=""/>
              </ImgContainer>
              <SidebarMenuTitle>Authorization</SidebarMenuTitle>
            </SidebarMenuItem>
          </StyledLink>
          <StyledLink to="/calendar">
            <SidebarMenuItem active={(location.pathname.includes("calendar") || location.pathname === "/calendar")}>
              <ImgContainer>
                <img src={appointmentsSvg} alt=""/>
              </ImgContainer>
              <SidebarMenuTitle>Calendar</SidebarMenuTitle>
            </SidebarMenuItem>
          </StyledLink>

          <SidebarMenuItem onClick={handleSettingsClick} active={settingsOpen}>
          <ImgContainer>
            <SettingsIcon style={{ color: 'white' }} />
          </ImgContainer>
          <SidebarMenuTitle>Settings</SidebarMenuTitle>
        </SidebarMenuItem>

        {/* Settings Modal */}
        <Menu
          anchorEl={anchorEl}
          open={settingsOpen}
          onClose={handleSettingsClose}
          MenuListProps={{
            'aria-labelledby': 'settings-button',
          }}
        >
      <MenuItem onClick={() => handleMenuItemClick('/users')}>
        <ListItemIcon>
          <PeopleIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Users</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('/userSchedule')}>
        <ListItemIcon>
          <CalendarTodayIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>User Schedules</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('/providers')}>
        <ListItemIcon>
          <BusinessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Providers</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('/services')}>
        <ListItemIcon>
          <BuildIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Services</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('/era')}>
        <ListItemIcon>
          <BuildIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>ERA</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('/test')}>
        <ListItemIcon>
          <BuildIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Test</ListItemText>
      </MenuItem>
        </Menu>
   

        </AuthenticatedTemplate>
      </SidebarMenuContainer>
  );
};
