import React from 'react';
import { Button, Grid, Box, Typography } from '@mui/material';
import { useGetClientServicesQuery } from '../../services/UtilityService';
import InputField from '../common/InputField';
import InputFieldFormik from '../common/InputFieldFormik';
import SelectFieldFormik from '../common/SelectFieldFormik';
import CheckboxField from '../common/CheckboxField';
import list from "../../shared/icons/list.svg";
import history from "../../shared/icons/history.svg";
import bluePen from "../../shared/icons/bluePen.svg";
import blueTrash from "../../shared/icons/blueTrash.svg";
import roundedPlus from "../../shared/icons/roundedPlus.svg";
import {
    ButtonContainer,
    ButtonsContainer,
    ClientsTitleContainer,
    EmptyMessage,
    HeaderContainer,
    ResultData,
    TableControlsContainer,
    TableSearchContainer,
    ToolsImg,
    UploadFileContainer,
    StyledButton,
} from './ClientAuthorizationForm.styled';
import useFetchWithMsal from '../../utils/useFetchWithMsal';

const ClientAuthorizedServiceForm = ({ index, remove }) => {

    const { skip } = useFetchWithMsal();
    const { data: services } = useGetClientServicesQuery("", { skip });

    return (
        <Box sx={{ padding: '1em', borderBottom: '1px solid #ccc', borderRadius: '4px', marginBottom: '1em' }}>
            <Grid container spacing={0.5} alignItems="center">
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].ProcedureCode`}
                        label="Procedure Code"
                        type="text"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].Modifier`}
                        label="Modifier"
                        type="text"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectFieldFormik
                        name={`AuthorizedServices[${index}].ServiceId`}
                        label="Service"
                        data={services}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].Rate`}
                        label="Rate"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputFieldFormik
                        name={`AuthorizedServices[${index}].Units`}
                        label="Units"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1} >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 2.5, }}>
                        <Button onClick={() => remove(index)} color="secondary" fullWidth startIcon={<ToolsImg src={blueTrash} alt="" />}>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ClientAuthorizedServiceForm;
