import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Form, Formik, useFormikContext } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/material/Alert";
import clientInfoFormModel from "../../constants/ClientInfoFormModel";
import { ClientInfoTools } from "../clientInfo/clientInfoTools/ClientInfoTools";
import {
  useGetAssessmentFormQuery,
  useGetAssessmentFormSignaturesQuery,
  useGetAssessmentValuesQuery,
  useGetUserInfoQuery,
  usePostAssessmentValuesMutation,
  useSignFormMutation,
  useUnsignFormMutation,
} from "../../services/ClientService";
import { useDispatch } from "react-redux";
import { setError } from "../../store/slices/ErrorSlice";
import { LoadingPanel } from "../../shared/components/LoadingPanel";
import { styled } from "@mui/system";
import useFetchWithMsal from "../../utils/useFetchWithMsal";
import AssessmentItem from "./accordionItem/AssessmentItem";
import { AssessmentUserInfo } from "./assessmentUserInfo/AssessmentUserInfo";
import { useParams } from "react-router-dom";
import { generateValidationSchema } from "../../utils/ValidationUtils";
import ASAMCriteriaItem from "./accordionItem/ASAMCriteriaItem";
import { Signatures } from "./signatures/Signatures";
import { Assignment } from "@mui/icons-material";
import { Assignments } from "./assignments/Assignments";
import { NotesPanel } from "../table/notesPanel/NotesPanel";

const HeaderWithTabs = styled(Box)({
  backgroundColor: "white",
  position: "sticky",
  top: "70px",
  zIndex: "100",
});
const Arrow = styled(ExpandMoreIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
const StyledAccordion = styled(Accordion)(({ theme, expanded }) => ({
  borderLeft: `3px solid ${
    expanded ? theme.palette.background.menu : theme.palette.background.active
  }`,
  marginBottom: "8px",
  width: "100%",
}));

export const PsychosocialAssessment = (props) => {
  const { id: paramId, formId: paramFormId, clientServiceId: paramClientServiceId } = useParams();
  const { propClientId, propFormId, propClientServiceId } = props;

  const id = propClientId ?? paramId;
  const formId = propFormId ?? paramFormId;
  const clientServiceId = propClientServiceId ?? paramClientServiceId;

  const { skip } = useFetchWithMsal();
  const { data, isLoading } = useGetAssessmentFormQuery(formId,  { skip });
  const { data: signatures } = useGetAssessmentFormSignaturesQuery({clientId: id, formId, clientServiceId},  { skip });
  const { data: assessmentValues } = useGetAssessmentValuesQuery({clientId: id, formId, clientServiceId}, { skip });
  const [notesVisible, setNotesVisible] = useState(false);
  const { data: basicInfo } = useGetUserInfoQuery({ skip });
  const [signForm] = useSignFormMutation();
  const [unsignForm] = useUnsignFormMutation();
  const [postAssessmentValues, { error }] = usePostAssessmentValuesMutation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState();
  const [validationSchema, setValidationSchema] = useState([]);
  const [initialValue, setInitialValue] = useState();
  const [expanded, setExpanded] = useState([1]);
  const [errorAccordion, setErrorAccordion] = useState([]);
  const [signatureId, setSignatureId] = useState();
  const [unsignId, setUnsignId] = useState();
  const allClientFormFields = [].concat(...clientInfoFormModel);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' or 'error'

  const formInitialValues = {};
  allClientFormFields.forEach((field) => {
    if (field.type === "checkbox") {
      formInitialValues[field.name] = false;
    } else if (field.type === "date") {
      formInitialValues[field.name] = null;
    } else {
      formInitialValues[field.name] = "";
    }
  });


  useEffect(() => {
    dispatch(setError(error?.message));
  }, [error]);

  useEffect(() => {
    if (assessmentValues) {
      setInitialValue(assessmentValues);
    }
  }, [assessmentValues]);

  useEffect(() => {
    if (data) {
      setActiveTab(data.sections[0]?.id);
      setExpanded([data.sections[0]?.id]);
      setValidationSchema(generateValidationSchema([data.validation]));
    }
  }, [data]);

  const onTabChange = (isValid) => (event, newValue) => {
    setExpanded([newValue]);
    setActiveTab(newValue);
    scrollToSection(newValue, isValid);
  };

  const onAccordionClick = (panel) => () => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded([panel]);
      setActiveTab(panel);
    }
  };
  const onCollapseClick = () => {
    setExpanded([]);
    setActiveTab(true)
  };

  const getSignatureId = (userSignatureId) => {
    setSignatureId(userSignatureId);
  };

  const getUnsignId = (userSignatureId) => {
    setUnsignId(userSignatureId);
  };

  const onSubmit = async (value, actions) => {

    try {
      // Call postClient mutation
      const result = await postAssessmentValues({ clientId: id, formId:formId, clientServiceId: clientServiceId, value }).unwrap(); // Use unwrap() for cleaner handling
      console.log("Result:", result);

      // Show success message
      setSnackbarMessage("form updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Reset form state
      actions.setTouched({});
      actions.setSubmitting(false);
      setInitialValue(value);
      console.log("form data", clientServiceId)
      if(signatureId) {
        signForm({userId: id, signatureId: signatureId, formId:formId, clientServiceId: clientServiceId})
      }
      if(unsignId) {
        unsignForm({userId: id, signatureId: unsignId, formId:formId, clientServiceId: clientServiceId})
      }
    } catch (error) {
      console.error("Error:", error);

      // Show error message
      setSnackbarMessage(error?.message || "Failed to update client. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }


/*     postAssessmentValues({ clientId: id, formId:formId, clientServiceId: clientServiceId, value });
    actions.setTouched({});
    actions.setSubmitting(false);
    setInitialValue(value);
    console.log("form data", clientServiceId)
    if(signatureId) {
      signForm({userId: id, signatureId: signatureId, formId:formId, clientServiceId: clientServiceId})
    }
    if(unsignId) {
      unsignForm({userId: id, signatureId: unsignId, formId:formId, clientServiceId: clientServiceId})
    } */
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return; // Avoid closing on clickaway
    setSnackbarOpen(false);
  };


  const renderGroups = (groups, id) => {
    const sortedGroups = groups
      ?.slice()
      .sort((a, b) => a.priority - b.priority);
    if (id === 76 || id === 40) {
      return sortedGroups?.map((group) => {
        return (
          <div key={group.id}>
            <Typography variant="h6" marginY={"20px"}>
              {group.name}
            </Typography>
            {group.formFields && (
              <ASAMCriteriaItem formFields={group.formFields} />
            )}
          </div>
        );
      });
    }
    return sortedGroups?.map((group) => {
      return (
        <div key={group.id}>
          <Typography variant="h6" marginY={"20px"}>
            {group.name}
          </Typography>
          {group.formFields && <AssessmentItem formFields={group.formFields} />}
        </div>
      );
    });
  };
  const renderTabContent = (formFields, index) => {
    return <AssessmentItem formFields={formFields} key={index} />;
  };

  const FormErrorNotification = React.memo(() => {
    const { isValid, errors, isSubmitting } = useFormikContext();

    const getIndex = (namesToFind) => {
      const indexOfAccordions = [];

      const extractFields = (obj) => {
        const array = [];
        if (obj.formFields) {
          obj.formFields.forEach((field) => {
            array.push(field.id);
          });
        }
        if (obj.groups) {
          obj.groups.forEach((group) => {
            group.formFields.forEach((field) => {
              array.push(...extractFields(group));
            });
          });
        }
        return array;
      };
      const sectionsFields = {};
      data.sections.forEach(
        (item) => (sectionsFields[item.id] = extractFields(item))
      );
      const keysArray = Object.keys(sectionsFields);

      keysArray.forEach((id) => {
        const filteredArray = sectionsFields[id].filter((el) =>
          namesToFind.includes(String(el))
        );
        if (filteredArray.length) indexOfAccordions.push(Number(id));
      });
      return indexOfAccordions;
    };

    useEffect(() => {
      if (!isValid) {
        const errorField = Object.keys(errors);
        const errorsIndex = getIndex(errorField);
        const areEqual =
          errorsIndex.length === errorAccordion.length &&
          errorsIndex.every((value, index) => value === errorAccordion[index]);
        if (!areEqual && isSubmitting) setErrorAccordion(errorsIndex);
      }
    }, [isValid, errors, isSubmitting]);

    return null;
  });

  const scrollToSection = (newValue, isValid) => {
    let top = 0;
    const index = data?.sections.findIndex((item) => item.id === newValue);
    window.scrollTo({
      top: top + 56 * (index + 1) + 120,
      behavior: "smooth",
    });
  };

  return (
    <Box sx={{ minWidth: "750px", position: "relative", width: "100%" }}>
      {(!data || isLoading) && <LoadingPanel />}
      {data && initialValue && (
        <Formik
          enableReinitialize
          initialValues={initialValue || data.fields}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={onSubmit}
          onReset={() => setErrorAccordion([])}
        >
          {({ isValid, dirty }) => (
            <Form id={"clientInfo"}>
              <FormErrorNotification />
              <HeaderWithTabs>
                <ClientInfoTools
                  onCollapseClick={onCollapseClick}
                  setNotesVisible={setNotesVisible}
                  onExpandAll={() =>
                    setExpanded(
                      data?.sections.map((section, index) => section.id)
                    )
                  }
                  name={data.formTitle}
                  isDirty={dirty}
                  id={id}
                />

                <Box
                  sx={{ borderBottom: 1, borderColor: "divider", mb: "22px" }}
                >
                  <Tabs
                    value={activeTab}
                    onChange={onTabChange(isValid)}
                    aria-label="tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {activeTab &&
                      data?.sections.map((section) => {
                        return (
                          <Tab
                            key={section.id}
                            label={section.name}
                            value={section.id}
                          />
                        );
                      })}
                      <Tab
                        key='11111'
                        label='Signatures'
                        value="111"
                      />
                  </Tabs>
                </Box>
              </HeaderWithTabs>
              <AssessmentUserInfo />

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ width: "100%" }}>
                  {data?.sections.map((section, index) => {
                    return (
                      <StyledAccordion
                        expanded={
                          expanded.includes(section.id) ||
                          errorAccordion.includes(section.id)
                        }
                        id={`accordion-${section.id}`}
                        onChange={onAccordionClick(section.id)}
                        key={`accordion-${section.id}`}
                      >
                        <AccordionSummary
                          expandIcon={<Arrow />}
                          aria-controls={`${section.id}-content`}
                          id={`${section.id}-header`}
                          sx={{ color: "background.main" }}
                        >
                          <Typography variant="accordion">
                            {section.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {section.formFields &&
                            renderTabContent(section.formFields)}
                          {section.groups &&
                            renderGroups(section.groups, section.id)}
                        </AccordionDetails>
                      </StyledAccordion>
                    );
                  })}
                    <StyledAccordion
                        expanded={
                          expanded.includes("111")
                        }
                        id={`accordion-111`}
                        onChange={onAccordionClick("111")}
                        key={`accordion-111`}
                      >
                        <AccordionSummary
                          expandIcon={<Arrow />}
                          aria-controls={`111-content`}
                          id={`111-header`}
                          sx={{ color: "background.main" }}
                        >
                          <Typography variant="accordion">
                            Signatures
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Signatures
                            basicInfo={basicInfo}
                            signatures={signatures}
                            requiredSignatures={data.requiredSignatures}
                            getSignatureId={getSignatureId}
                            getUnsignId={getUnsignId}
                            clientServiceId={clientServiceId}
                          />
                        </AccordionDetails>
                      </StyledAccordion>
                      {/* <StyledAccordion
                        expanded={
                          expanded.includes("222")
                        }
                        id={`accordion-222`}
                        onChange={onAccordionClick("222")}
                        key={`accordion-222`}
                      >
                        <AccordionSummary
                          expandIcon={<Arrow />}
                          aria-controls={`222-content`}
                          id={`222-header`}
                          sx={{ color: "background.main" }}
                        >
                          <Typography variant="accordion">
                            Assignments
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Assignments/>
                        </AccordionDetails>
                      </StyledAccordion> */}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      {notesVisible && <NotesPanel type='client-form' visibility={notesVisible} setVisibility={setNotesVisible}/>}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          marginRight: "20%", // Add right margin here
        }}

      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}
          sx={{
            width: "100%",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // Add box shadow here
            borderRadius: "8px", // Optional: Add rounded corners
          }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
