import React from "react";
import { Field, ErrorMessage } from "formik";
import { Select, MenuItem, FormHelperText, FormControl, InputLabel, Box } from "@mui/material";

const SelectFieldFormik = (props) => {
  const { name, label, data, disabled, required, height, width, maxLength, step } = props;
  const requiredLabel = required ? `${label}*` : label;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <Box sx={{ width: '100%' }}>
          <InputLabel
            htmlFor={name}
            sx={{
              fontSize: "0.8rem",
              height: "14px",
              //color: "rgba(0, 0, 0, 0.6)",
              marginBottom: "9px" // Space between label and select box
            }}
          >
            {requiredLabel}
          </InputLabel>
          <FormControl fullWidth required={required} error={Boolean(form.errors[name])}>
            <Select
              id={name}
              {...field}  // Bind Formik field props directly to Select
              onChange={(event) => form.setFieldValue(name, event.target.value)}
              disabled={disabled}
              sx={{
                height: height || "30px",
                maxWidth: width || "290px",
                width: '100%',
                fontSize: "0.8rem", // Match text input font size
                border: form.errors[name] ? "1px solid red" : 'none',
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,  // Optional: limit dropdown height
                  },
                },
              }}
              inputProps={{
                maxLength: maxLength,
                step: step || "1"
              }}
            >
              {data && data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage name={name} component="div">
              {(msg) => <FormHelperText error>{msg}</FormHelperText>}
            </ErrorMessage>
          </FormControl>
        </Box>
      )}
    </Field>
  );
};

export default React.memo(SelectFieldFormik);
