import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useGetERAQuery, useDownloadFileERAMutation } from '../../services/ClientService'; // Replace with your actual import path

const ERAGrid = () => {
  // Fetch ERA data using the useGetERAQuery hook
  const { data: eraData, isLoading, error } = useGetERAQuery(0);
  const [downloadFileERA] = useDownloadFileERAMutation();

    // Log data to confirm it is being received correctly
    useEffect(() => {
      if (eraData) {
        console.log('ERA Data received:', eraData);
      }
      if (error) {
        console.error('Error fetching ERA data:', error);
      }
    }, [eraData, error]);

  console.log('Data:', eraData);
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const handleDownloadERA = async (ERAId) => {
    try {
      console.log('ERAId for download:', ERAId);
      const response = await downloadFileERA({ ERAId }).unwrap();
      /* const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `ERA_${ERAId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); */
    } catch (error) {
      console.error('Error downloading the file:', error);
      alert('Failed to download the file.');
    }
  };
  const columns = [
    { field: 'checkNumber', headerName: 'Check Number', width: 150 },
    { field: 'checkType', headerName: 'Check Type', width: 150 },
    { field: 'sourceProvName', headerName: 'Source Provider Name', width: 200 },
    { field: 'downloadTime', headerName: 'Download Time', width: 200 },
    {
      field: 'eraId',
      headerName: 'ERA ID',
      width: 150,
      renderCell: (params) => (
        <span
          style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => handleDownloadERA(params.value)}
        >
          {params.value}
        </span>
      ),
    },
    { field: 'paidDate', headerName: 'Paid Date', width: 150},
    { field: 'payerName', headerName: 'Payer Name', width: 200 },
    { field: 'payerId', headerName: 'Payer ID', width: 150 },
    { field: 'provName', headerName: 'Provider Name', width: 200 },
    { field: 'provNPI', headerName: 'Provider NPI', width: 150 },
    { field: 'provTaxId', headerName: 'Provider Tax ID', width: 150 },
    { field: 'receivedTime', headerName: 'Received Time', width: 200 },
  ];

  return (
    <Box
      sx={{ width: '100%', maxWidth: "calc(100% - 60px)", margin: "37px 30px" }}
    >
      <h2>ERA Records</h2>
      <div style={{ height: '600px', width: '100%', margin: "20px 0" }}>
        <DataGrid
          rows={eraData || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5, 10, 25]}
          getRowId={(row) => row.eraId} // Use 'eraId' as the unique identifier
          sx={{
            backgroundColor: 'white',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'white',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
          }}
        />
      </div>
    </Box>
  );
};

export default ERAGrid;