import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel, FormHelperText } from "@mui/material";
import dayjs from "dayjs";
import { useField } from "formik";

const DatePickerFieldFormik = ({ label, maxDate, height, width, required, ...props }) => {
  // Use Formik's useField hook to get field and meta objects
  const [field, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const onChange = (date) => {
    if (date) {
      try {
        const ISODateString = date.toISOString();
        setValue(ISODateString);
      } catch (error) {
        setValue(date);
      }
    } else {
      setValue(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <InputLabel htmlFor={field.name} sx={{ height: "14px" }}>
          {label}
          {required && "*"}
        </InputLabel>
      )}
      <DatePicker
        {...props}
        id={field.name}
        maxDate={maxDate && dayjs(maxDate)}
        value={field.value ? dayjs(field.value) : null}
        onChange={onChange}
        sx={{
          height: "auto",
          maxWidth: width || "180px",
          width: "100%",
          minWidth: "10px",
          "& .MuiInputBase-root": { border: meta.touched && meta.error ? "1px solid red" : "none" },
        }}
        slotProps={{
          textField: {
            size: "small",
            error: meta.touched && !!meta.error,
            helperText: meta.touched && meta.error ? meta.error : "",
            minWidth: "10px",
            required: required,
          },
        }}
      />
      {meta.touched && meta.error && (
        <FormHelperText error sx={{ marginTop: "4px" }}>
          {meta.error}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
};

export default DatePickerFieldFormik;
