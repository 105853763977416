import React from "react";
import { FormHelperText, InputLabel, TextareaAutosize, styled } from "@mui/material";
import { useField } from "formik";

export const TextareaFieldStyled = styled(TextareaAutosize)(
  () => `
  background: rgba(248, 239, 231, 1);
  padding: 5px;
  border: none;
  resize: none;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
`,
);

const TextAreaFieldFormik = ({ label, height, width, minRows, ...props }) => {
  // Use Formik's useField hook to bind the field to the form state
  const [field, meta] = useField(props);

  return (
    <>
      {label && (
        <InputLabel htmlFor={field.name} sx={{ height: "14px", marginBottom: '0' }}>
          {label}
        </InputLabel>
      )}
      <TextareaFieldStyled
        {...field}
        {...props}
        style={{ height: height || "30px", maxWidth: width || "100%", width: '100%' }}
        minRows={minRows || 3}
      />
      {meta.touched && meta.error && (
        <FormHelperText error sx={{ marginTop: "4px" }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export default TextAreaFieldFormik;
