import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Chip, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AutocompleteWithCheckboxesForFilters(props) {
  const { data, name, label, labelType, isError, setValue, errorText, value, multiple, grouped, width, ...rest } = props;

  const [selectAll, setSelectAll] = useState(false);

  const removeExtraFields = object => {
    if (object) {
      return {
        id: object.id,
        name: object?.name || ""
      }
    }
  }

  const findElementById = id => {
    if (data) {
      return data.find(element => element.id === id) || null;
    }
  }

  const [columns, setColumns] = useState(
    value > 0
      ? multiple ? [removeExtraFields(findElementById(value))] : removeExtraFields(findElementById(value))
      : multiple ? [] : null
  );

  useEffect(() => {
    setValue(name, columns);
  }, [columns]);

  const handleToggleSelectAll = () => {
    setSelectAll((prev) => {
      if (!prev) setColumns([...data]);
      else setColumns([]);
      return !prev;
    });
  };

  return (
    <Box sx={{ textAlign: 'start' }}>
      {label ? 
        <InputLabel id={label}>
          <Typography variant={labelType || "body1"}>{label}</Typography>
        </InputLabel>
      : null }
      <FormControl {...rest} error={isError} style={{ width: width || '100%' }}>
        <Autocomplete
          multiple={multiple}
          groupBy={grouped && ((option) => option?.group)}
          id="checkboxes-tags-demo"
          options={data ? data : []}
          value={columns}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(_e, value, reason) => {
            if (reason === "clear" || reason === "removeOption") setSelectAll(false);
            if (reason === "selectOption" && value.length === data.length) setSelectAll(true);
            setColumns(value);
          }}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;

            return (
              <>
                {numTags === 1 ? value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={option?.name || null}
                    sx={{ height: '24px' }} // Fixed height for chip
                  />
                )) : value[0].name}

                {numTags > limitTags && ` +${numTags - limitTags} more`}
              </>
            );
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                key={option.id}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.name || ""}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: '100%',
                '.MuiInputBase-root': {
                  height: '40px', // Set fixed height for the input field
                }
              }}
            />
          )}
          PaperComponent={multiple && ((paperProps) => {
            const { children, ...restPaperProps } = paperProps;
            return (
              <Paper {...restPaperProps}>
                <Box
                  onMouseDown={(e) => e.preventDefault()} // prevent blur
                  pl={1.5}
                  py={0.5}
                >
                  <FormControlLabel
                    onClick={(e) => {
                      e.preventDefault(); // prevent blur
                      handleToggleSelectAll();
                    }}
                    label="Select all"
                    control={
                      <Checkbox id="select-all-checkbox" checked={selectAll} />
                    }
                  />
                </Box>
                <Divider />
                {children}
              </Paper>
            );
          })}
        />
        {isError && <FormHelperText>{errorText}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
