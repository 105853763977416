import * as React from 'react';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { Box, Button, ButtonGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { DateRangePickerDay as MuiDateRangePickerDay } from '@mui/x-date-pickers-pro/DateRangePickerDay';
import { Height } from '@mui/icons-material';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(({ theme }) => ({
  variants: [
    {
      props: ({ isHighlighting, outsideCurrentMonth }) =>
        !outsideCurrentMonth && isHighlighting,
      style: {
        borderRadius: 0,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    {
      props: ({ isStartOfHighlighting }) => isStartOfHighlighting,
      style: {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },
    },
    {
      props: ({ isEndOfHighlighting }) => isEndOfHighlighting,
      style: {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
      },
    },
  ],
}));

// Styled DateRangeCalendar with custom background and border
const StyledDateRangeCalendar = styled(DateRangeCalendar)({
  '.MuiTypography-root': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    fontSize: '0.9rem !important', // Increase font size
    fontWeight: '500 !important', // Set font weight
    lineHeight: '1.6 !important', // Adjust line height for better spacing
  },
  '.MuiDateRangeCalendar-monthContainer': {
    maxHeight: '300px',
  }
});

const SmallButton = styled(Button)(({ theme }) => ({
  fontSize: '0.7rem', // Smaller font size
  padding: '4px 8px', // Smaller padding for a more compact look
}));

export const ServiceCalendarPicker = ({ onClose, open, type, clientId, onChange }) => {
  const [selectedDateRange, setSelectedDateRange] = React.useState([null, null]);

  // Helper functions to calculate date ranges
  const calculateDateRange = (range) => {
    const today = dayjs();
    switch (range) {
      case 'today':
        return [today, today];
      case 'yesterday':
        return [today.subtract(1, 'day'), today.subtract(1, 'day')];
      case 'tomorrow':
        return [today.add(1, 'day'), today.add(1, 'day')];
      case 'thisWeek':
        return [today.startOf('week'), today.endOf('week')];
      case 'lastWeek':
        return [
          today.subtract(1, 'week').startOf('week'),
          today.subtract(1, 'week').endOf('week')
        ];
      case 'nextWeek':
        return [
          today.add(1, 'week').startOf('week'),
          today.add(1, 'week').endOf('week')
        ];
      case 'thisMonth':
        return [today.startOf('month'), today.endOf('month')];
      case 'lastMonth':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month')
        ];
      case 'nextMonth':
        return [
          today.add(1, 'month').startOf('month'),
          today.add(1, 'month').endOf('month')
        ];
      default:
        return [null, null];
    }
  };

    // Handlers for date range changes
    const handleDateRangeChange = (newRange) => {
      const calculatedRange = calculateDateRange(newRange);
      setSelectedDateRange(calculatedRange);
  
      // Trigger the onChange callback if provided
      if (onChange) {
        onChange(calculatedRange);
      }
    };
  
    const handleCalendarChange = (newRange) => {
      setSelectedDateRange(newRange);
  
      // Trigger the onChange callback if provided
      if (onChange) {
        onChange(newRange);
      }
    };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', 
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: 2,
          width: 'fit-content',
          height: '400px', // Fixed height
          overflow: 'hidden', // Prevent overflow causing scrollbars
          marginTop: '8px',
        }}
      >
        {/* Calendar on the left side */}
        <StyledDateRangeCalendar
          calendars={1}
          value={selectedDateRange}
          onChange={handleCalendarChange}
          slots={{ day: DateRangePickerDay }}
        />

        {/* Button groups stacked vertically and centered on the right side */}
        <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 2 }}>
          {/* Day Buttons */}
          <ButtonGroup size="small" orientation="vertical" sx={{ marginBottom: 2 }}>
            <SmallButton onClick={() => handleDateRangeChange('yesterday')}>Yesterday</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('today')}>Today</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('tomorrow')}>Tomorrow</SmallButton>
          </ButtonGroup>

          {/* Week Buttons */}
          <ButtonGroup size="small" orientation="vertical" sx={{ marginBottom: 2 }}>
            <SmallButton onClick={() => handleDateRangeChange('lastWeek')}>Last Week</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('thisWeek')}>This Week</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('nextWeek')}>Next Week</SmallButton>
          </ButtonGroup>

          {/* Month Buttons */}
          <ButtonGroup size="small" orientation="vertical">
            <SmallButton onClick={() => handleDateRangeChange('lastMonth')}>Last Month</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('thisMonth')}>This Month</SmallButton>
            <SmallButton onClick={() => handleDateRangeChange('nextMonth')}>Next Month</SmallButton>
          </ButtonGroup>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
