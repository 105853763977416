import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    CircularProgress,
    Typography,
    Box,
} from "@mui/material";
import moment from "moment";
import {
    HourglassEmpty as HourglassEmptyIcon,
    Delete as DeleteIcon,
    Block as BlockIcon,
    Cancel as CancelIcon,
    Redo as RedoIcon,
    AttachMoney as AttachMoneyIcon,
    CheckCircle as CheckCircleIcon,
    Description as DescriptionIcon,
    Sync as SyncIcon,
    Error as ErrorIcon,
    Person as PersonIcon,
} from "@mui/icons-material";

const billingStatusIcons = {
    "Submitted - Pending": <HourglassEmptyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Write-Off": <DeleteIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Void": <BlockIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Denied": <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Adjusted": <RedoIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Partial payment": <AttachMoneyIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Paid in full": <CheckCircleIcon fontSize="small" style={{ marginRight: '8px', color: 'green' }} />,
    "Rejected": <CancelIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Un-Billed": <DescriptionIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Re-Submitted": <SyncIcon fontSize="small" style={{ marginRight: '8px', color: 'gray' }} />,
    "Error": <ErrorIcon fontSize="small" style={{ marginRight: '8px', color: 'red' }} />,
};

export const ServiceStatusHistoryStepper = ({ isHistoryDialogOpen, handleCloseHistoryDialog, isLoading, error, billingHistory }) => {
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (billingHistory && billingHistory.length > 0) {
            // Find the index of the step with the greatest ID
            const maxIdIndex = billingHistory.reduce((maxIndex, item, index, arr) =>
                item.id > arr[maxIndex].id ? index : maxIndex, 0);
            setActiveStep(maxIdIndex);
        }
    }, [billingHistory]);

    return (
        <Dialog
            id="billing-history-dialog"
            open={isHistoryDialogOpen}
            onClose={handleCloseHistoryDialog}
            aria-labelledby="billing-history-dialog-title"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="billing-history-dialog-title">Service History</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography color="error">Error loading billing history</Typography>
                ) : billingHistory && billingHistory.length > 0 ? (
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ padding: 2 }}>
                        {billingHistory.map((history, index) => (
                            <Step key={index} expanded>
                                <StepLabel
                                    icon={billingStatusIcons[history.status] || <DescriptionIcon fontSize="small" />}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {history.status} - {moment(history.statusDate).format('MM/DD/YYYY h:mm A')}
                                </StepLabel>
                                <StepContent>
                                    <Box sx={{ marginBottom: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                            <PersonIcon fontSize="small" sx={{ mr: 1}} />
                                            <Typography variant="body2">{history.createdBy}</Typography>
                                        </Box>
                                        <Typography variant="body2">
                                            <strong>Action:</strong> {history.billingHistoryAction}
                                        </Typography>
                                        {history.note && (
                                            <Typography variant="body2">
                                                {history.note || 'N/A'}
                                            </Typography>
                                        )}
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                ) : (
                    <Typography>No billing history available</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseHistoryDialog} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
