import { setError } from "../store/slices/ErrorSlice";
import { clientAPI } from "./ClientService";

const utilityApi = clientAPI.injectEndpoints({
  endpoints: (build) => ({
    getClientStatuses: build.query({
      query: () => ({
        url: `/api/utility/GetClientStatuses`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientHousings: build.query({
      query: () => ({
        url: `/api/Utility/GetClientHousings`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientCounselors: build.query({
      query: () => ({
        url: `/api/Utility/GetClientCounselors`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getCompanyBranches: build.query({
      query: () => ({
        url: `/api/utility/GetCompanyBranches`,
      }),
      transformErrorResponse: (response) => {
        build.dispatch(setError(response.data.Message));
      },
    }),
    getTracks: build.query({
      query: () => ({
        url: `/api/utility/GetTracks`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getRelationshipToInsured: build.query({
      query: () => ({
        url: `/api/utility/ClientInsurance/dropdown/GetRelationshipToInsured`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getGender: build.query({
      query: () => ({
        url: `/api/utility/ClientInsurance/dropdown/Gender`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getRelationToPatient: build.query({
      query: () => ({
        url: `/api/utility/ClientInsurance/dropdown/RelationToPatient`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getPrimaryPayer: build.query({
      query: () => ({
        url: `/api/utility/ClientInsurance/dropdown/PrimaryPayer`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getGenders: build.query({
      query: () => ({
        url: `/api/utility/GetGenders`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getSexualOrientations: build.query({
      query: () => ({
        url: `/api/utility/GetSexualOrientations`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getGenderIdentities: build.query({
      query: () => ({
        url: `/api/utility/GetGenderIdentities`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getEthnicities: build.query({
      query: () => ({
        url: `/api/utility/GetEthnicities`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getMaritalStatuses: build.query({
      query: () => ({
        url: `/api/utility/GetMaritalStatuses`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getRequestedServices: build.query({
      query: () => ({
        url: `/api/utility/GetRequestedServices`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getClientServices: build.query({
      query: () => ({
        url: `/api/Utility/ClientService/dropdown/Service`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
    getLevelOfCare: build.query({
      query: () => ({
        url: `api/Utility/ClientBasicInformation/dropdown/LevelOfCare`,
      }),
      transformErrorResponse: (response) => {
        return { message: response.data.Message };
      },
    }),
  }),
  overrideExisting: false,
});
export const {
  useGetClientStatusesQuery,
  useGetClientCounselorsQuery,
  useGetClientHousingsQuery,
  useGetCompanyBranchesQuery,
  useGetTracksQuery,
  useGetRelationToPatientQuery,
  useGetRelationshipToInsuredQuery,
  useGetGenderQuery,
  useGetPrimaryPayerQuery,
  useGetGendersQuery,
  useGetSexualOrientationsQuery,
  useGetGenderIdentitiesQuery,
  useGetEthnicitiesQuery,
  useGetMaritalStatusesQuery,
  useGetRequestedServicesQuery,
  useGetClientServicesQuery,
  useGetLevelOfCareQuery,
  useGetHousesQuery,
} = utilityApi;
